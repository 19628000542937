
<template>
  <div class="agenda">

    <event-modal ref="eventModal" @showOverlay="(status) => this.$emit('showOverlay', status)" @atualizar="(event) => this.atualizarEvento(event)" @excluir="(event) => this.excluirEvento(event)"/>

  <!-- b-popover class="box-filtro-adic-prof" target="popover-reactive-1" triggers="click" :show.sync="popoverShow" placement="auto"
      container="my-container" ref="popover" @show="onShow" @shown="onShown" @hidden="onHidden">
      <div class="container-filtro-adicional-profissional px-1">
            <v-select multiple :filterable="false" @search="onSearchProfissional" :disabled="perfilLogado === 'PROF'"
              v-model="itemProfissional" :on-change="filtroAlterado" @input="filtroAlterado"
              :options="listaProfissionaisSearch" label="nome" ref="selectProfisionalSearch" placeholder="Profissional">
              <template slot="no-options">
                Digite para pesquisar..
              </template>
              <template slot="option" slot-scope="option">
                <div class="d-center">
                  {{ option.nome }}
                </div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div class="selected d-center">
                  {{ option.nome }}
                </div>
              </template>
            </v-select>
      </div>      
      </b-popover !-->

      <b-popover v-if="targetInfoProf" :target="targetInfoProf" :show.sync="showInfoProf" placement="top" >      
        <card class="m-0 p-0">
          <template slot="header">
            <h6 class="mb-0">Dados do profissional</h6>
          </template>

          <b-card-body class="m-0 p-0">
            <div><b>Nome:</b>{{ infoProf.nome }}</div>
            <div><b>Crm:</b> {{infoProf.crm }}</div>
            <div><b>Telefone:</b> {{ infoProf.telefone }}</div>
            <div><b>Email:</b> {{ infoProf.email }}</div>
          </b-card-body>
        </card>
      </b-popover>

    <context-menu :display="showContextMenu" ref="menu">
      <b-list-group class="menu-plantao">
        <b-list-group-item @click="divulgar">
          <b-icon icon="bell-fill" variant="success" class="mr-1"></b-icon>Anunciar</b-list-group-item>
      </b-list-group>
    </context-menu>

    <confirma-relatorio-financeiro id="consolidado" ref="confRelFinConsolidado" @executou="(r) => { if (r.status === 'success') exportarExcelConsolidado(r.escolha) }" />
    <confirma-relatorio-financeiro id="detalhado" ref="confRelFinDetalhado" @executou="(r) => { if (r.status === 'success') exportarExcelDetalhado(r.escolha) }" />
    <confirma-relatorio-financeiro id="calendario" ref="confRelCalendario" @executou="(r) => { if (r.status === 'success') exportarExcelCalendario(r.escolha) }" />
    <filtro-divulgar ref="filtroDivulgar" @showOverlay="(status) => this.$emit('showOverlay', status)" @executou="(r) => { if (r.status === 'success') enviarNotificacaoDivulgarVaga(r.filtro) }" />
      

    <b-modal ref="modal-conf-copia-agenda" id="modal-conf-copia-agenda" size="lg" title="Confirmação" hide-footer>
      <div class="container-fluid">
        <b-row class="my-1">
          <div class="col-md-12">
            {{ msgConfirma }}
          </div>
        </b-row>
        <b-row class="my-1">
          <div class="col-md-12">
            <b-form-checkbox v-model="manterSituacao" value="S" unchecked-value="N">Manter situação dos plantões?</b-form-checkbox>
            <b-form-checkbox v-model="quinzeDias" value="S" unchecked-value="N">Escala do profissional é de 15 em 15dias?</b-form-checkbox>
            <b-form-checkbox v-model="valorComplementar" value="S" unchecked-value="N">Copiar valores complementares?</b-form-checkbox>
            <b-form-checkbox v-model="profissionalNaoAlocado" value="S" unchecked-value="N">Copiar profissional do plantão quando não existir profissional alocado(fixo)?</b-form-checkbox>
          </div>
        </b-row>
        <b-row class="my-1">
          <div class="col-md-12">
            <button size="sm" style="margin-left: 4px" class="btn btn-danger btn-fill float-right"
              @click="$bvModal.hide('modal-conf-copia-agenda')">
              Cancelar
            </button>
            <button size="sm" class="btn btn-success btn-fill float-right" @click="copiarAgenda">
              Continuar
            </button>
          </div>
        </b-row>
      </div>
    </b-modal>

    <b-modal ref="modal-conf-excluir-agenda" id="modal-conf-excluir-agenda" size="lg" title="Confirmação" hide-footer>
      <div class="container-fluid">
        <b-row class="my-1">
          <div class="col-md-12">
            {{ msgConfirma }}
          </div>
        </b-row>
        <b-row class="my-1">
          <div class="col-md-12">
            <button size="sm" style="margin-left: 4px" class="btn btn-danger btn-fill float-right"
              @click="$bvModal.hide('modal-conf-excluir-agenda')">
              Não
            </button>
            <button size="sm" class="btn btn-success btn-fill float-right" @click="excluirAgenda">
              Sim
            </button>
          </div>
        </b-row>
      </div>
    </b-modal>

    <b-card class="my-0 py-0" style="border-top: 0;" body-class="py-1">
      <div class="row">
        <div class="col-md-4 filtro" v-if="idFechamento === null && selectedFiltroApresentacao !== 'P'">
          <filtro-local @showOverlay="(status) => this.$emit('showOverlay', status)" :itemLocal="itemLocal"
            @update="itemLocal = $event; filtroLocalAlterado()" :multiple="true"></filtro-local>
        </div>

        <div class="col-md-3 m-0 p-0" v-if="(true || selectedFiltroApresentacao === 'P') && perfilLogado !== 'PROF'">
          <b-row class="m-0 p-0">
            <div class="col-md-12 m-0 p-0 filtro">

              <v-select :disabled="perfilLogado === 'PROF'" multiple v-model="itemProfissional"
                @open="listarProfissionais" :on-change="filtroAlterado" @input="filtroAlterado"
                :options="itensProfissionais" label="nome" ref="selectProfissional" placeholder="Profissional"
                :filterBy="filterBy">

                <template #selected-option="{ id, nome }">
                  <div style="font-size:0.6em">
                    <span>{{ nome }} </span>
                  </div>
                </template>

                <template slot="no-options">
                  Digite para pesquisar..
                </template>

                <template slot="option" slot-scope="option">
                  <div class="d-center">
                    {{ option.nome }}
                  </div>
                </template>

              </v-select>
            </div>
          </b-row>

          <b-row class="m-0 px-0 pt-1" v-if="isPnc">
            <div class="col-md-12 m-0 p-0 filtro">

              <v-select multiple @open="listarProfissionais" :disabled="perfilLogado === 'PROF'"
                v-model="itemProfissionalPnc" :on-change="filtroAlterado" @input="filtroAlterado"
                :options="itensProfissionais" label="nome" ref="selectProfisionalPnc" placeholder="Profissional">

                <template #selected-option="{ id, nome }">
                  <div style="font-size:0.6em">
                    <span>{{ nome }} </span>
                  </div>
                </template>

                <template slot="no-options">
                  Digite para pesquisar..
                </template>

                <template slot="option" slot-scope="option">
                  <div class="d-center text-wrap">
                    {{ option.nome }}
                  </div>
                </template>

              </v-select>

            </div>
          </b-row>

        </div>

        <div class="col-md-2 filtro" v-if="selectedFiltroApresentacao !== 'P'">
          <v-select multiple v-model="itemTipo" :options="itensTipo" @input="filtroAlterado" @open="buscarTipo"
            label="descricao" ref="selectTipo" placeholder="Tipo de serviço">

            <template #selected-option="{ id, descricao }">
              <div style="font-size:0.6em">
                <span>{{ descricao }} </span>
              </div>
            </template>

            <template slot="no-options">
              Nenhum tipo de serviço encontrado
            </template>

            <template slot="option" slot-scope="option">
              <div class="d-center text-wrap">
                {{ option.descricao }}
              </div>
            </template>

          </v-select>
        </div>

        <div class="col-md-2 filtro" v-if="selectedFiltroApresentacao !== 'P'">
          <v-select multiple v-model="itemSituacao" :options="itensSituacao" :on-change="filtroAlterado"
            @input="filtroAlterado" label="descricao" ref="select-situacao" placeholder="Situação"
            @open="listarSituacoes">

            <template #selected-option="{ id, descricao }">
              <div style="font-size:0.6em">
                <span>{{ descricao }} </span>
              </div>
            </template>

            <span slot="no-options" @click="$refs.select.open = false">
              Nenhum registro encontrado
            </span>
          </v-select>
        </div>

        <div class="col-md-1 filtro" v-if="selectedFiltroApresentacao !== 'P'">
          <v-select v-model="filtroTroca" :options="optionsFiltroTroca" @input="filtroAlterado" label="text"
            :reduce="ft => ft.value" ref="select-filtro-troca" placeholder="Troca?">
          </v-select>
        </div>
      </div>

      <div class="row">

        <div class="col-md-1 filtro mt-1" v-if="false">
          <b-dropdown text="Relatórios" variant="primary" size="sm">
            <b-dropdown-item @click="imprimirAgenda">Escala em PDF</b-dropdown-item>
            <b-dropdown-item @click="exportarExcel">Escala em Excel</b-dropdown-item>
            <b-dropdown-item @click="confirmaRelatorioConsolidado">Consolidado por profissional</b-dropdown-item>
            <b-dropdown-item @click="confirmaRelatorioDetalhado">Detalhado por profissional</b-dropdown-item>            
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item v-for="rel in relatorios" :key="rel.id" @click="confirmaRelatorioPersonalizado(rel.id)">{{rel.label}}</b-dropdown-item>
          </b-dropdown>
        </div>

      </div>
    </b-card>

    <card>

      <div class="row my-1">

        <div class="col-md-4">

          <b-input-group size="sm">
            <b-input-group-prepend>
              <b-button size="sm" variant="primary" class="btn-fill float-left mr-2" @click="today"> Hoje</b-button>
              <b-button size="sm" variant="primary" class="btn-fill" @click="prev">
                <b-icon icon="arrow-left-circle"></b-icon>
              </b-button>
            </b-input-group-prepend>

            <b-input-group-text class="py-0 px-2">
              <span style="width: 140px; font-size: 1.2em; color: #3f7bb7;"> {{ dataAtualCalendario }}</span>
            </b-input-group-text>

            <b-input-group-append>
              <b-button size="sm" variant="primary" class="btn-fill" @click="next">
                <b-icon icon="arrow-right-circle"></b-icon>
              </b-button>

            </b-input-group-append>
          </b-input-group>
        </div>

        <div class="col-md-2">
          <b-dropdown text="Relatórios" variant="primary" size="sm" v-if="isPermiteRelatorios">
            <b-dropdown-item v-if="isRelEscalaPdf" @click="imprimirAgenda">Escala em PDF</b-dropdown-item>
            <b-dropdown-item @click="confirmaRelatorioCalendario">Escala em Excel</b-dropdown-item>
            <b-dropdown-item v-if="isRelEscalaPlanilha" @click="exportarExcel">Escala em Planilha</b-dropdown-item>
            <b-dropdown-item v-if="isRelConsPorProf" @click="confirmaRelatorioConsolidado">Consolidado por profissional</b-dropdown-item>
            <b-dropdown-item v-if="isRelDetPorProf" @click="confirmaRelatorioDetalhado">Detalhado por profissional</b-dropdown-item>
            <b-dropdown-item v-for="rel in relatorios" :key="rel.id" @click="confirmaRelatorioPersonalizado(rel.id)">{{rel.label}}</b-dropdown-item>
            <b-dropdown-item v-if="isRelFolhaPonto" @click="exportarRelatorioFolhaPonto">Folha de Ponto</b-dropdown-item>
                        
          </b-dropdown>

          <b-dropdown text="Ação" variant="primary" size="sm" class="ml-1" v-if="isPermiteAcoes">
            <b-dropdown-item v-if="isCopiar" @click="confirmaCopiaAgenda">Copiar</b-dropdown-item>
            <b-dropdown-item v-if="isExcluir" @click="confirmaExcluirAgenda">Excluir</b-dropdown-item>
            <b-dropdown-item v-if="isDivulgar" @click="divulgar">Divulgar</b-dropdown-item>
          </b-dropdown>
        </div>

        <div class="col-md-5">

        </div>

        <div class="col-md-1">
          <b-dropdown :text="visao" variant="primary" size="sm" class="">
            <b-dropdown-item href="#" @click.prevent="changeView('dayGridMonth')">Mensal</b-dropdown-item>
            <b-dropdown-item v-if="false" href="#" @click.prevent="changeView('customPorProfissionais')">Profissional</b-dropdown-item>
            <b-dropdown-item v-if="false" href="#" @click.prevent="changeView('customPorLocais')">Local</b-dropdown-item>
            <b-dropdown-item href="#" @click.prevent="changeView('customDiario')">Diário</b-dropdown-item>
          </b-dropdown>
        </div>
      </div>

      <div v-if="visao === 'Diário'">
        <lista-diario :list="calendarOptions.events" :dia="diaAtualCalendario" @showOverlay="(status) => this.$emit('showOverlay', status)" @abrirAgenda="(r) => abrirAgenda(r)" @atualizar="(event) => this.atualizarEvento(event)"/>
      </div>

      <div class="row" v-bind:class="{ copiarOff: !isCopiar, imprimirOff: false, excluirOff: !isExcluir }">
        <FullCalendar id="calendar" ref="calendarEscala" class="demo-app-calendar dayspan"
          style="z-index:5; height: 27em !important;" :options="calendarOptions">

          <template v-slot:eventContent="arg">

            <!-- container-fluid centro -->
            <div v-if="arg.event.id < 0">
              <div v-if="permiteIncluirAgenda" style="padding-top: 5px; background-color: #fff; padding:0 0.3em; ">
                <button type="button" style="width: 100%; border:none; background-color: #fff; color: #d3d3d3;  "> +
                </button>
              </div>
            </div>

            <div v-else-if="permiteAbrirAgenda" class="container-fluid centro"
              v-bind:style="'border-left: ' + arg.event.extendedProps.corLocal + ' solid 3px;' + (arg.event.extendedProps.corFundo ? 'background-color: ' + arg.event.extendedProps.corFundo + ';' : '')">

<!-- comantado -->

            <!-- div class="row" v-if="selectedFiltroApresentacao === 'S'">
                <div class="col-md-8 titulo">
                  <i>{{
                      arg.event.extendedProps.idProfissional !==
                        undefined &&
                        arg.event.extendedProps.idProfissional !== null
                        ? arg.event.extendedProps.nomeProfissional
                        : ""
                  }}
                  </i>
                </div>

                <div class="col-md-2 horario"
                  v-bind:style="'border-bottom: 3px solid ' + arg.event.extendedProps.cor + ';'">
                  <label>{{ arg.event.extendedProps.duracaoHoraMinuto }}
                    {{ arg.event.extendedProps.horarioDe }}
                  </label>
                </div>

                <div class="col-md-2 top">
                  <i v-if="
                    arg.event.extendedProps.idProfissional ===
                    undefined ||
                    arg.event.extendedProps.idProfissional === null
                  " class="nc-icon nc-send"></i>
                </div>
              </div !-->

              <div class="row" v-if="selectedFiltroApresentacao === 'P'">
                <div class="col-md-8 titulo" style="text-decoration: none">
                  <i style="text-decoration: none">{{arg.event.extendedProps.nomeLocal}}</i>
                </div>
                <div class="col-md-2 horario" v-bind:style="
                  'border-bottom: 3px solid ' + arg.event.extendedProps.cor + ';'
                ">
                  <label style="text-decoration: none">{{ arg.event.extendedProps.duracaoHoraMinuto }}
                    {{ arg.event.extendedProps.horarioDe }}</label>
                </div>
                <div class="col-md-2 top">
                  <i style="text-decoration: none" v-if="
                    arg.event.extendedProps.idProfissional ===
                    undefined ||
                    arg.event.extendedProps.idProfissional === null
                  " class="nc-icon nc-send"></i>
                </div>
                </div>

                <!-- comantado -->

              <b-row v-if="selectedFiltroApresentacao === 'L'">

                <b-col cols="1">
                  <b-link @click="abrirMenuPlantao($event, arg)" href="#" class="text-primary">
                    <b-icon icon="menu-button-wide" scale="0.75"></b-icon>
                  </b-link>
                </b-col>

                <b-col cols="9" class="titulo elipse-js" v-bind:style="'color: ' + arg.event.extendedProps.corTipo + ';'">
                  <i v-if="!isTipoSelecionadoComUmServico">{{ arg.event.extendedProps.dscTipo }}</i>
                </b-col>

                <b-col class="duracao" cols="2" v-bind:class="arg.event.extendedProps.idMotivoTroca ? 'troca' : !arg.event.extendedProps.idProfissionalEfetivo && arg.event.extendedProps.idProfissional ? 'prof-nao-alocado' : ''">
                  <label class="my-0">
                    {{ arg.event.extendedProps.exibeDuracao === 'S' ? arg.event.extendedProps.duracaoHoraMinuto : '' }}
                  </label>
                </b-col>

                <b-col cols="10" class="titulo" style="text-decoration: none">
                  <i :id="`nome-prof-${arg.event.id}`" @mouseleave="fecharInfoProfissional" @mouseover="abrirInfoProfissional(arg.event.id)" >{{ arg.event.extendedProps.idProfissional !== undefined && arg.event.extendedProps.idProfissional !== null ? arg.event.extendedProps.nomeProfissionalComCrm : "" }}</i>
                  <b-icon v-if="arg.event.extendedProps.valorCompl > 0" class="mx-1" variant="danger" icon="plus-circle"></b-icon>
                  <b-icon v-if="arg.event.extendedProps.avista === 'S'" class="mx-1" variant="success" icon="cash"></b-icon>
                  <b-icon v-if="arg.event.extendedProps.divulga === 'S'" class="mx-1" variant="success" icon="bell"></b-icon>
                  <b-icon v-if="arg.event.extendedProps.modoPlanejamento === 'A'" class="mx-1" variant="info" icon="flag"></b-icon>
                  <b-icon v-if="arg.event.extendedProps.idProfissionalFavorecido" class="mx-1" variant="warning" icon="arrow-return-left"></b-icon>
                </b-col>

                <b-col class="horario" cols="2"
                  v-bind:style="'border-bottom: 3px solid ' + arg.event.extendedProps.cor + ';'">
                  <label style="text-decoration: none" class="my-0">
                    {{ arg.event.extendedProps.horarioDe }}
                    {{ arg.event.extendedProps.horarioAte }}
                  </label>
                </b-col>

              <!-- b-popover :target="`popover-1-${arg.event.id}`" placement="auto" title="Popover!" triggers="hover focus"
                  variant="info" >
                  <b-row>
                    <b-col>{{arg.event.extendedProps.dscTipo}}</b-col>
                    <b-col>col 2</b-col>
                  </b-row>
                
                
                  </b-popover !-->

              <!-- b-col cols="1" class="top">                        
                        <b-icon icon="arrow-up-right-circle-fill" variant="primary" v-if="arg.event.extendedProps.idMotivoTroca"></b-icon>                        
                        </b-col !-->

              </b-row>
            </div>

          </template>
        </FullCalendar>
      </div>

      <b-row v-if="idFechamento !== null" class="mt-2">
        <div class="col-md-2" v-if="alteraSituacaoFechamento">
          <b-form-select v-model="idSituacaoFechamento" value-field="id" text-field="descricao"
            @change="mudouSituacaoFechamento = true" :options="itensSituacaoFechamento" size="sm">
          </b-form-select>
        </div>
        <div class="col-md-2">
          <button v-if="mudouSituacaoFechamento" class="btn btn-success btn-fill btn-sm ml-2"
            @click="alterarSituacaoFechamento">
            Confirmar
          </button>
          <button v-if="exportarFechamento" class="btn btn-primary btn-fill btn-sm ml-2" @click="exportarHmtj">
            Exportar
          </button>
        </div>
      </b-row>
    </card>

  </div>
</template>

<script>
import Card from "src/components/Cards/Card.vue";
import FullCalendar, { requestJson } from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import ListPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import EventModal from "./EventModal";
import CommentsModal from "../components/CommentsModal";
import calendarioPorLocalPlugin from "../layout/fullcalendar/listalocais";
import calendarioPorProfissionalPlugin from "../layout/fullcalendar/listaprofissionais";
import calendarioEmptyPlugin from "../layout/fullcalendar/EmptyCustomView";
import ListaDiario from "../layout/fullcalendar/ListaDiario.vue";
import useAuthentication from '@/composables/useAuthentication'
import FiltroLocal from './comuns/FiltroLocal'
import ConfirmaRelatorioFinanceiro from './comuns/ConfirmaRelatorioFinanceiro.vue'
import FiltroDivulgar from './comuns/FiltroDivulgar.vue'
import ContextMenu from './comuns/ContextMenu.vue'

import axios from "axios";
import { BASE_CONSTANTS } from "../service/config";
import AgendaService from "../service/AgendaService";
import funcao from "src/components/Funcao";
import appUtils from '@/composables/utils'
import _ from 'lodash';

export default {
  components: {
    Card,
    FullCalendar,
    EventModal,
    FiltroLocal,
    ConfirmaRelatorioFinanceiro,
    FiltroDivulgar,
    ContextMenu,    
    ListaDiario,
  },
  async mounted() {

    const token = String(localStorage.getItem("authorization")).replaceAll(
      '"',
      ""
    );

    if (token === null || token === undefined || token === "" || token === "null") {
      this.$router.push("/login");
    } else {

      this.http = axios.create({
        baseURL: BASE_CONSTANTS.urlBase,
        headers: {
          Authorization: "bearer " + String(localStorage.getItem("authorization")).replaceAll('"', ""),
          "Content-Type": "application/json",
          Accept: "*/*",
        },
      });

      axios.post(BASE_CONSTANTS.urlBase + "oauth/check_token/?token=" + token)
        .then((resp) => {
          this.definirFiltro();
          //this.listarLocais();
          let tmp = this.$route.params.idFechamento;
          if (tmp !== undefined) {
            this.idFechamento = parseInt(atob(tmp), 10);
            if (!isNaN(this.idFechamento)) {
              this.buscarFechamento()
            } else {
              alert("Inválido")
            }
          }
        })
        .catch((error) => {
          this.$router.push("/login");
        });
    }

    this.isCopiar = this.hasPermissionWith('AGENDA', 'COPIAR_ESCALA', 'E')
    this.isExcluir = this.hasPermissionWith('AGENDA', 'EXCLUIR_ESCALA', 'E')    
    this.isDivulgar = this.hasPermissionWith('AGENDA', 'DIVULGAR_VAGA', 'E')

    this.isPermiteAcoes = this.isCopiar || this.isExcluir || this.isDivulgar 

    this.isRelEscalaPdf = this.hasPermissionWith('RELATORIO', 'ESCALA_EM_PDF', 'E');
    this.isRelEscalaPlanilha = this.hasPermissionWith('RELATORIO', 'ESCALA_EM_PLANILHA', 'E');
    this.isRelConsPorProf = this.hasPermissionWith('RELATORIO', 'CONSOLIDADO_POR_PROFISSIONAL', 'E');
    this.isRelDetPorProf = this.hasPermissionWith('RELATORIO', 'DETALHADO_POR_PROFISSIONAL', 'E');
    this.isRelFolhaPonto = this.hasPermissionWith('RELATORIO', 'FOLHA_PONTO', 'E');
    
    this.isPermiteRelatorios = this.hasAccess('RELATORIO')

    this.exibirValorVenda = this.hasPermissionWith('RELATORIO_FINANCEIRO', 'PRECO_VENDA', 'E')
    this.exibirValorCompra = this.hasPermissionWith('RELATORIO_FINANCEIRO', 'PRECO_COMPRA', 'E')
    this.permiteAbrirAgenda = this.hasPermission('AGENDA', 'DETALHE_AGENDA', 'E')
    this.permiteIncluirAgenda = this.hasPermission('AGENDA', 'GRAVAR', 'E')


    
    await this.gerarEventosMais()
    this.status = ""

    this.buscarRelatorios();


  },
  data() {
    return {
      http: null,
      show: false,
      showFrom: "",
      filaShow: [],
      msgConfirma: "",
      perfilLogado: "",
      guardaItens: null,
      filteredProfissionais: null,
      status: "iniciando",
      furo: "border-left: #d35400 solid 3px;",
      naoFuro: "border-left: #27ae60 solid 3px;",
      dataEventoAnt: null,
      eventosArmazenados: [],
      itensProfissionais: [],
      listaProfissionaisSearch: [],
      //itensArmazenados: [],
      itensLocal: [],
      itensTipo: [],
      itensSituacao: [],
      itemProfissional: null,
      itemProfissionalPnc: null,
      itemLocal: null,
      itemTipo: null,
      itemSituacao: null,
      filtroTroca: null,
      optionsFiltroTroca: [
        { value: 'T', text: 'Troca' },
        { value: 'TNA', text: 'Troca e Não alocado' },
        { value: 'NA', text: 'Não alocado' },
        { value: 'ST', text: 'S/ troca' },        
      ],
      calendarEl: document.getElementById("calendar"),
      selectedFiltroApresentacao: "L",
      filtroApresentacao: [],
      idFechamento: null,
      abreFechamento: false,
      idSituacaoFechamento: null,
      idLocalFechamento: null,
      alteraSituacaoFechamento: false,
      exportarFechamento: false,
      importaFechamento: false,
      mudouSituacaoFechamento: false,
      itensSituacaoFechamento: [],
      manterSituacao: 'N',
      quinzeDias: 'N',
      valorComplementar: 'N',
      profissionalNaoAlocado: 'N',      
      isCopiar: false,
      isExcluir: false,      
      isDivulgar: false,
      isPermiteAcoes: false,
      isRelEscalaPdf: false,
      isRelEscalaPlanilha: false,
      isRelConsPorProf: false,
      isRelDetPorProf: false,
      isPermiteRelatorios: false,
      exibirValorVenda: false,
      exibirValorCompra: false,
      permiteAbrirAgenda: false,
      popoverShow: false,
      dataAtualCalendario: "",
      diaAtualCalendario: "",
      showContextMenu: false,
      acaoEvento: false,
      relatorios: [{ id: 1, label: "rel 1" }, { id: 2, label: "rel 2" }],
      showInfoProf: false,
      targetInfoProf: null,
      infoProf: {
        nome: null,
        crm: null,
        telefone: null
      },
      argAtual: null,
      nomesMes: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],      
      visao: 'Mensal',
      headerButtom: {
        left: "prev,next today customButtonCopiar customButtonImprimir customButtonExcluir",
        center: "title",
        right: "dayGridMonth,customPorLocais,customPorProfissionais",
      },
      calendarOptions: {
        //dayMaxEventRows: true,        
        //height: 700,
        aspectRatio: 2.5,
        locale: "pt-br",
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          ListPlugin,
          interactionPlugin,
          calendarioPorProfissionalPlugin,
          calendarioPorLocalPlugin,
          calendarioEmptyPlugin,
          // needed for dateClick
        ],
        headerToolbar: false,
        /*headerToolbar: {
          //left: "prev,next today customButtonCopiar customButtonImprimir customButtonExcluir customButtonPlanilha",
          left: "prev,next today customButtonCopiar customButtonExcluir",
          center: "title",
          right: "dayGridMonth,customPorLocais,customPorProfissionais",
        },*/
        buttonText: {
          today: "Hoje",
          month: "Mensal",
          customPorLocais: "Semanal",
          customPorProfissionais: "Profissional",
        },
        customButtons: {
          myCustomButton: {
            text: "Criar Agenda",
            click: function () {
              var dateStr = prompt("Enter a date in YYYY-MM-DD format");
              var date = moment(dateStr);

              if (date.isValid()) {
                $("#calendar").fullCalendar("renderEvent", {
                  title: "dynamic event",
                  start: date,
                  allDay: true,
                });
                alert("Great. Now, update your database...");
              } else {
                alert("Invalid date.");
              }
            },
          },
          customButtonCopiar: {
            text: "Cópia",
            click: this.confirmaCopiaAgenda,
          },
          customButtonImprimir: {
            text: "Imprimir",
            click: this.imprimirAgenda,
          },
          customButtonExcluir: {
            text: "Excluir",
            click: this.confirmaExcluirAgenda,
          },
          customButtonPlanilha: {
            text: "Planilha",
            click: this.exportarExcel,
          },
        },
        initialView: "dayGridMonth",
        initialEvents: JSON.parse(localStorage.getItem("eventosArmazenados")),
        allDaySlot: true,
        editable: true,
        /* dayCellDidMount: function(info)
             {
               info.el.innerHTML += "<button class='dayButton' data-date='" + info.date + "' style='width:100%'> + </button>";
             },
             dayCellContent : {
               html: `<button style="width: 80%; margin:2px; border:1px solid #d3d3d3; background-color: #fff; border-radius:5px; color: #d3d3d3;"> + </button>`,
            },
            dayCellDidMount: (arg) => {
              if(arg.el.classList.contains("fc-day-future")){
                var theElement = arg.el.querySelectorAll(".fc-daygrid-day-frame > .fc-daygrid-day-events")[0]
                setTimeout(function(){
                  if(theElement.querySelectorAll(".fc-daygrid-event-harness").length === 0){ // check there's no event
                    // theElement.innerHTML = theElement.innerHTML + '<div class="text-center"> <span style="color:#d3d3d3; font-weight: bold;"> + </span> </span></div>';
                    theElement.innerHTML = theElement.innerHTML + '<div class="" style="padding:0 0.3em;" > <button style="width: 100%; border:1px solid #d3d3d3; background-color: #fff; border-radius:5px; color: #d3d3d3;">+</button> </span></div>';
                  }
                }, 10)
              }
            },*/
        selectable: true,
        selectMirror: true,
        dayMaxEvents: false,
        weekends: true,
        showNonCurrentDates: false,
        events: [],
        select: this.handleEventClick,
        eventClick: this.handleEventClick,
        //eventsSet: this.handleEvents,
        datesSet: this.handleAction,
        eventOrder: "ordem"
        /* you can update a remote database when these fire:
          eventAdd:
          eventChange:
          eventRemove:
          */
        /*validRange: function(nowDate) {
          
            let ini = '2021-0'+nowDate.getMonth()+'-20'
            let fim = '2021-0'+(nowDate.getMonth()+1)+'-19'
            
            return {
              start: ini,
              end: fim
            };
          },*/

        /*visibleRange: function(currentDate) {
          alert('a') 
          // Generate a new date for manipulating in the next step
          var startDate = new Date(currentDate.valueOf());
          var endDate = new Date(currentDate.valueOf());

          // Adjust the start & end dates, respectively
          startDate.setDate(startDate.getDate() - 1); // One day in the past
          endDate.setDate(endDate.getDate() + 2); // Two days into the future

          return { start: startDate, end: endDate };
        }*/
      },
      currentEvents: [],
      type: ["", "info", "success", "warning", "danger"],
      notifications: {
        topCenter: false,
      },
    };
  },
  computed: {
    isTipoSelecionadoComUmServico() {
      return this.itemTipo != null && this.itemTipo.length == 1
    },
    isPnc() {
      let prof = this.itemProfissional
      return prof != null && prof.length > 0 && this.itemProfissional.filter((p) => { return p.pnc === 'N' }).length == 0 ? true : false
    },
  },
  methods: {
    abrirInfoProfissional(id) {      
      this.targetInfoProf = 'nome-prof-' + id

      //let event = this.calendarOptions.events.filter((f) => { return f.id === parseInt(id) })

      let api = this.$refs.calendarEscala.getApi()
      var event = api.getEventById(parseInt(id))
      this.infoProf.nome = event.extendedProps.nomeProfissional
      this.infoProf.crm = event.extendedProps.crm
      this.infoProf.telefone = maskPhone(event.extendedProps.telefoneProfissional)
      this.infoProf.email = event.extendedProps.emailProfissional
      this.showInfoProf = true
    },
    fecharInfoProfissional() {      
      this.targetInfoProf = null
      this.showInfoProf = false
    },
    filterBy(option, label, search) {
      const { filterBy } = appUtils(this)
      return filterBy(option, label, search)      
    },    
    prev() {
      let api = this.$refs.calendarEscala.getApi()
      api.prev()
    },
    next() {
      let api = this.$refs.calendarEscala.getApi()
      api.next()
    },
    today() {
      let api = this.$refs.calendarEscala.getApi()
      api.today()
    },
    atualizarDataAtual() {
      if (this.$refs.calendarEscala.getApi()) {
        let api = this.$refs.calendarEscala.getApi()
        if (this.visao === 'Diário') this.dataAtualCalendario = dateToStr(api.getDate(), 'dd/MM/yyyy')        
        else this.dataAtualCalendario = this.nomesMes[api.getDate().getMonth()] + "/" + api.getDate().getFullYear()
        this.diaAtualCalendario = api.getDate()
      }
    },
    changeView(v) {
      let view = v
      if(v === 'customDiario') view = 'customEmpty'
      let api = this.$refs.calendarEscala.getApi()

      let dataAtual = new Date()

      const isMesAtual = dataAtual.getMonth() === api.getDate().getMonth() && dataAtual.getFullYear() === api.getDate().getFullYear();
      
      if(isMesAtual) api.gotoDate(dataAtual)

      api.changeView(view);      
      this.visao = v === 'dayGridMonth' ? 'Mensal' : v === 'customPorProfissionais' ? 'Profissional' : v === 'customPorLocais' ? 'Local' : 'Diário'
    },
    getDataCalendario() {
      let api = this.$refs.calendarEscala.getApi()
      api.getDate();
    },
    async buscarFechamento() {

      const { toast, ativarShow, desativarShow } = appUtils(this)

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));
      let dadosConsulta =
        '{ "id":' +
        this.idFechamento +
        ', "idUsuario": ' +
        dadosUsuario.idPessoa +
        "}";

      let continua = await AgendaService.buscarFechamento(
        this.http,
        dadosConsulta
      )
        .then((resposta) => {
          let retorno = resposta.data;
          if (retorno.status === "SUCCESS") {
            let api = this.$refs.calendarEscala.getApi()
            let fechamento = retorno.obj;
            api.gotoDate(fechamento.dtIni);
            this.idSituacaoFechamento = fechamento.idSituacao;
            this.idLocalFechamento = fechamento.idLocal;
            this.listaSituacoesFechamento();
            this.alteraSituacaoFechamento = fechamento.atualiza === "S";
            this.exportarFechamento = fechamento.exportar === "S";
            this.carregaDados();
            return true;
          } else {
            toast(retorno.mensagens[0].descricao, "Falha", "danger");
          }
        })
        .catch((e) => { });
      return false;
    },
    async carregaDados() {

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

      let isProfissional = dadosUsuario.perfilProfissional === 'S'
      let isCoordenador = dadosUsuario.perfilCoordenador === 'S'
      let isOnlyProfissional = isProfissional && !isCoordenador

      let temFiltro = isOnlyProfissional ||
        (this.itemLocal != null && this.itemLocal.length > 0) ||
        (this.itemProfissional != null && this.itemProfissional.length > 0) ||
        (this.itemTipo != null && this.itemTipo.length > 0) ||
        (this.itemSituacao != null && this.itemSituacao.length > 0) ||
        (this.idFechamento !== undefined && this.idFechamento > 0);

      if (!this.isPnc) {
        this.itemProfissionalPnc = null
      }

      let api = this.$refs.calendarEscala.getApi()

      let calendarOptions = this.calendarOptions

      api.removeAllEvents();
      calendarOptions.events = []

      const { toast, ativarShow, desativarShow } = appUtils(this)

      if (temFiltro) {

        ativarShow("carregaDados")

        let idsLocal = []
        let idsProfissional = []
        let idsProfissionalPnc = []
        let idsTipo = []
        let idsSituacao = []
        let semProfissional = null
        if (this.itemLocal != null) {
          this.itemLocal.forEach((item) => {
            idsLocal.push(item.id)
          })
        }

        if (isOnlyProfissional) {
          idsProfissional.push(dadosUsuario.idPessoa)
        } else if (this.itemProfissional != null) {

          if (this.itemProfissional.length === 1 && this.itemProfissional[0].id === 0) {
            semProfissional = 'S'
          } else {

            this.itemProfissional.forEach((item) => {
              idsProfissional.push(item.id);
            });

            if (this.isPnc && this.itemProfissionalPnc != null) {
              this.itemProfissionalPnc.forEach((item) => {
                idsProfissionalPnc.push(item.id);
              });
            }

          }
        }

        if (this.itemTipo != null) {
          this.itemTipo.forEach((item) => {
            idsTipo.push(item.id);
          });
        }
        if (this.itemSituacao != null) {
          this.itemSituacao.forEach((item) => {
            idsSituacao.push(item.id);
          });
        }

        const dataAtual = api.getDate();

        const mesAtual = dataAtual.getMonth() + 1;
        const anoAtual = dataAtual.getFullYear();

        let dadosConsultaEventos = {
          "mes": mesAtual,
          "ano": anoAtual,
          "idUsuario": dadosUsuario.idPessoa,
          "idTabela": dadosUsuario.grupo.idTabela,
          "idGrupoUsuario": dadosUsuario.grupo.id,
          "idEmpresa": this.selectedFiltroApresentacao !== 'P' ? dadosUsuario.grupo.empresa.id : null,
          "coordenador": dadosUsuario.perfilCoordenador,
          "idsLocal": idsLocal,
          "idsProfissional": idsProfissional,
          "idsProfissionalPnc": idsProfissionalPnc,
          "idsTipo": idsTipo,
          "idsSituacao": idsSituacao,
          "idFechamento": this.idFechamento !== undefined ? this.idFechamento : 0,
          "troca": this.filtroTroca ? this.filtroTroca : null,
          "semProfissional": semProfissional
        }

        this.status = "buscandoAgenda";
        AgendaService.listaEventosFilter(this.http, dadosConsultaEventos)
          .then((resposta) => {
            let lista = resposta.data.obj;
            this.status = "buscouAgenda";
            calendarOptions.events = lista;
            this.gerarEventosMais()
            desativarShow("carregaDados");
            this.abreFechamento = false;

          })
          .catch((err) => {
            console.log(err)
            toast("Não foi possível carregar escala", "Aviso", "danger");
            this.status = "buscouAgenda";
            desativarShow("carregaDados");
            const { isUnauthorized } = useAuthentication()
            const isLogin = isUnauthorized(err)
            if (isLogin) this.$router.push("/login");
          });

      } else {
        this.status = "buscouAgenda";
        this.gerarEventosMais()
        //toast("Informe local, profissional ou tipo para buscar a agenda", 'Falha', 'danger')
      }

    },
    async gerarEventosMais() {

      let api = this.$refs.calendarEscala.getApi()

      let calendarOptions = this.calendarOptions;

      var data = api.view.activeStart;
      const mesAtual = data.getMonth();
      let lista = []

      let id = -1;

      while (data.getMonth() === mesAtual) {

        var dataEvento = dateToStr(data, 'yyyy-MM-dd');

        lista.push({
          id: id,
          title: "[NOVO_REGISTRO]",
          start: dataEvento,
          end: dataEvento,
          allDay: true,
          ordem: 1
        }
        )

        id--

        data.setDate(data.getDate() + 1);
      }

      calendarOptions.events.push(...lista)

    },
    atualizarEvento(item) {

      let event = this.calendarOptions.events.filter((f) => { return f.id === item.id })
      if(event) this.calendarOptions.events.splice(this.calendarOptions.events.indexOf(event), 1, item)
        
              //event = item
              //this.calendarOptions.events.splice(this.calendarOptions.events.indexOf(event), 1)
              let api = this.$refs.calendarEscala.getApi()
              var ev = api.getEventById(item.id);
              
              if (ev != null) {
                ev.remove()
              }

              api.addEvent({
              id: item.id,
              horarioDe: item.horarioDe,
              dtAgenda: item.dtAgenda,
              dtUltimaAlteracao: new Date(item.dtUltimaAlteracaoStr),
              exibeDuracao: item.exibeDuracao,
              duracaoHoraMinuto: item.duracaoHoraMinuto,
              duracao: item.duracao,
              horarioAte: item.horarioAte,
              allDay: true,
              start: item.dtAgenda,
              end: item.dtAgenda,
              title: item.nomeProfissional,
              idLocal: item.idLocal,
              nomeLocal: item.nomeLocal,
              idTipo: item.idTipo,
              dscTipo: item.dscTipo,
              idProfissional: item.idProfissional,
              nomeProfissional: item.nomeProfissional,
              nomeProfissionalComCrm: item.nomeProfissionalComCrm,
              telefoneProfissional: item.telefoneProfissional,
              emailProfissional: item.emailProfissional,
              crm: item.crm,
              idSituacao: item.idSituacao,
              idRecorrencia: item.idRecorrencia,
              cor: item.cor,
              corTipo: item.corTipo,
              corLocal: item.corLocal,
              corFundo: item.corFundo,
              idProfissionalEfetivo: item.idProfissionalEfetivo,
              nomeProfissionalEfetivo: item.nomeProfissionalEfetivo,
              idProfissionalPnc: item.idProfissionalPnc,
              nomeProfissionalPnc: item.nomeProfissionalPnc,
              idMotivoTroca: item.idMotivoTroca,
              avista: item.avista,
              valorCompl: item.valorCompl,
              fixo: item.fixo,
              ordem: 0
            })

    },
    excluirEvento(item) {
      let api = this.$refs.calendarEscala.getApi()
      var ev = api.getEventById(item.id);
      if (ev != null) {
        ev.remove()
      }
    },
    definirFiltro() {

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

      let isProfissional = dadosUsuario.perfilProfissional === 'S'
      let isCoordenador = dadosUsuario.perfilCoordenador === 'S'
      let isOnlyProfissional = isProfissional && !isCoordenador

      this.perfilLogado =
        isOnlyProfissional
          ? "PROF" : isCoordenador
            ? "COORD"
            : "USER";

      if (isOnlyProfissional) {
        this.selectedFiltroApresentacao = 'P'
        this.carregaDados()
      }

      /*if (this.perfilLogado !== "PROF") {
        this.filtroApresentacao.push({ value: "P", text: "Por Profissional" });
        this.filtroApresentacao.push({ value: "L", text: "Por Local" });
      } else {
        this.filtroApresentacao.push({ value: "L", text: "Por Local" });
      }*/

    },
    /*listarLocais() {

      const { toast, ativarShow, desativarShow } = appUtils(this)

      ativarShow("listarLocais");

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

      let dadosConsulta = "";

      dadosConsulta = {
        "idUsuario": dadosUsuario.idPessoa,
        "ativo": 'S'
      };

      AgendaService.listaLocaisFilter(dadosConsulta)
        .then((resposta) => {
          this.itensLocal = resposta.data.obj;
          if (this.itensLocal.length > 0) {
            //this.itemLocal = this.itensLocal[0]
          }
          desativarShow("listarLocais");
        })
        .catch((err) => {
          this.itensLocal = JSON.parse(localStorage.getItem("itensLocal"));
          desativarShow("listarLocais");
        });

    },*/
    listarProfissionais() {

      if (this.itensProfissionais.length == 0) {

        const { ativarShow, desativarShow } = appUtils(this)

        ativarShow("listarProfissionais");

        let idsLocal = [];
        if (this.itemLocal != null) {
          this.itemLocal.forEach((item) => {
            idsLocal.push(item.id);
          });
        }

        let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

        let dadosConsulta = {
          "idUsuario": dadosUsuario.idPessoa,
          "idsLocal": idsLocal,
          "idEmpresa": dadosUsuario.grupo.empresa.id
        }

        AgendaService.listaProfissionaisFilter(this.http, dadosConsulta)
          .then((resposta) => {
            this.itensProfissionais = resposta.data.obj;
            this.itensProfissionais.push({ id: 0, nome: '[Sem profissional]', pnc: 'N' })
            desativarShow("listarProfissionais");
          })
          .catch(() => {
            desativarShow("listarProfissionais");
          });
      }

    },
    listarTipos() {
      const { ativarShow, desativarShow } = appUtils(this)

      ativarShow("listarTipos");

      let idsLocal = [];
      if (this.itemLocal != null) {
        this.itemLocal.forEach((item) => {
          idsLocal.push(item.id);
        });
      }

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

      let dadosConsulta = {
        "idUsuario": dadosUsuario.idPessoa,
        "idsLocal": idsLocal
        //"ativo": 'S'
      }

      AgendaService.listaTiposFilter(dadosConsulta)
        .then((resposta) => {
          this.itensTipo = resposta.data.obj;
          desativarShow("listarTipos");
        })
        .catch((error) => {
          desativarShow("listarTipos");
        });
    },
    listarSituacoes() {
      const { ativarShow, desativarShow } = appUtils(this)

      if (this.itensSituacao.length == 0) {

        ativarShow("listarSituacoes");

        /*let idsLocal = [];
        if (this.itemLocal != null) {
          this.itemLocal.forEach((item) => {
            idsLocal.push(item.id);
          });
        }*/

        let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

        let dadosConsulta = {
          "idUsuario": dadosUsuario.idPessoa,
          //',"idsLocal": [' +idsLocal +'], 
          "acima": true,
          "abaixo": true
        };

        AgendaService.recuperaSituacoes(dadosConsulta)
          .then((resposta) => {
            this.itensSituacao = resposta.data.obj;
            desativarShow("listarSituacoes");
          })
          .catch((error) => {
            desativarShow("listarSituacoes");
          });
      }
    },
    changeItemProfissional(event) {
      this.itemLocal = null;
      this.carregaDados();
    },
    changeItemLocal(event) {
      this.carregaDados();
    },
    filtroLocalAlterado(event) {

      this.filtroAlterado(event)
      this.itensProfissionais = []
      this.itemTipo = null
      this.itensTipo = []

    },
    filtroAlterado(event) {
      this.carregaDados();
    },
    handleWeekendsToggle() {
      this.calendarOptions.weekends = !this.calendarOptions.weekends; // update a property
    },
    newEvent() {
      this.$modal.show(
        Modal,
        {
          text: "Text",
        },
        {
          height: "auto",
        }
      );
    },
    renderEvent(arg) {
      let span = document.createElement("span");
      span.setAttribute("class", "fa fa-comments");
      arg.el.appendChild(span);
      span.addEventListener("click", (event) => {
        event.stopPropagation();
        this.$modal.show(CommentsModal, {
          text: "This is for the comments",
        });
      });
    },
    handleAction(action) {
      this.atualizarDataAtual()
      if (this.status === "buscouAgenda") {
        this.status = "";
      } else if (this.status === "") {
        this.carregaDados();
      }
    },
    handleEvents() {

    },

    updateEvent() {
      // this.$store.commit("UPDATE_EVENT", arg.event)
    },
    handleEventClick(arg) {
      if (this.acaoEvento) {
        this.acaoEvento = false;
        return
      }

      if (this.permiteAbrirAgenda) {

        /*let calendarApi = arg.view.calendar;

        if (arg.event !== undefined) {
          arg.event.api = calendarApi;

          const param = {
            event: arg.event,
            idLocalEscala: this.itemLocal !== null && this.itemLocal.length === 1 ? this.itemLocal[0].id : 0,
            idTipoEscala: this.itemTipo !== null && this.itemTipo.length === 1 ? this.itemTipo[0].id : 0,
          }

          this.$refs.eventModal.abrir(param)
        }*/

        if (arg.event !== undefined) {

          const event = {
            id: arg.event.id,
            dtAgenda: arg.event.start,
            idRecorrencia: arg.event.idRecorrencia
          }

          this.abrirAgenda(event)
        }

      }
    },
    abrirAgenda(arg) {
      if (this.permiteAbrirAgenda) {        
        arg.api = this.$refs.calendarEscala.getApi()
        arg.idLocalEscala = this.itemLocal !== null && this.itemLocal.length === 1 ? this.itemLocal[0].id : 0
        arg.idTipoEscala = this.itemTipo !== null && this.itemTipo.length === 1 ? this.itemTipo[0].id : 0
        this.$refs.eventModal.abrir(arg)
      }
    },
    confirmaCopiaAgenda(arg) {
      this.manterSituacao = "N"
      this.quinzeDias = "N"
      this.valorComplementar = "N"
      this.msgConfirma = "Confirma cópia do mês anterior para o mês selecionado?"
      this.$bvModal.show("modal-conf-copia-agenda")
    },
    confirmaExcluirAgenda(arg) {
      this.msgConfirma = "Confirma exclusão das agendas selecionadas?"
      this.$bvModal.show("modal-conf-excluir-agenda")
    },
    divulgar() {
      this.$refs.filtroDivulgar.abrir()
    },
    enviarNotificacaoDivulgarVaga(filtro) {

      const { toast, ativarShow, desativarShow } = appUtils(this)

      ativarShow('enviarNotificacaoDivulgarVaga')

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

      let idsAgenda = []
      idsAgenda.push(this.argAtual.event.id)
      let idsClassificacoes = filtro.classificacoes.map(elem => elem.id)
      let idsLocais = filtro.locais.map(elem => elem.id)

      let dadosConsulta = {
        "idUsuario": dadosUsuario.idPessoa,
        "idTabela": dadosUsuario.grupo.idTabela,
        "ids": idsAgenda,
        "idsClassificacao": idsClassificacoes,
        "idsLocal": idsLocais
      }

      AgendaService.anunciarVaga(dadosConsulta)
        .then((resposta) => {
          let retorno = resposta.data;
          if (retorno.status === "SUCCESS") {
            toast(retorno.mensagens[0].descricao, "Sucesso", "success");
          } else {
            toast(retorno.mensagens[0].descricao, "Falha", "danger");
          }
          desativarShow('enviarNotificacaoDivulgarVaga')
        })
        .catch((e) => {
          desativarShow('enviarNotificacaoDivulgarVaga')
          toast(e, "Falha", "danger");
        });


    },
    copiarAgenda() {

      const { toast, ativarShow, desativarShow } = appUtils(this)

      this.$bvModal.hide("modal-conf-copia-agenda")

      ativarShow("copiarAgenda")

      let api = this.$refs.calendarEscala.getApi()
      let dataAtual = api.getDate()

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"))

      let idsLocal = []
      let idsProfissional = []
      let idsTipo = []
      let idsSituacao = []
      if (this.itemLocal != null) {
        this.itemLocal.forEach((item) => {
          idsLocal.push(item.id);
        });
      }
      if (this.itemProfissional != null) {
        this.itemProfissional.forEach((item) => {
          idsProfissional.push(item.id);
        });
      }
      if (this.itemTipo != null) {
        this.itemTipo.forEach((item) => {
          idsTipo.push(item.id);
        });
      }
      if (this.itemSituacao != null) {
        this.itemSituacao.forEach((item) => {
          idsSituacao.push(item.id);
        });
      }
      
      let dadosEnvio = {
        "idUsuario":  dadosUsuario.idPessoa,
        "idsLocal":  idsLocal,
        "idsProfissional": idsProfissional,
        "idsTipo": idsTipo,
        "idsSituacao": idsSituacao,
        "data": dateToStr(dataAtual, "yyyy-MM-dd"),
        "manterSituacao": this.manterSituacao,
        "quinzeDias": this.quinzeDias,
        "valorComplementar": this.valorComplementar,
        "profissionalNaoAlocado": this.profissionalNaoAlocado
        }

      AgendaService.copiarAgenda(this.http, dadosEnvio)
        .then((resposta) => {
          this.show = false;
          let retorno = resposta.data;
          if (retorno.status === "SUCCESS") {
            //calendarOptions.events = retorno.obj;
            this.carregaDados();
            toast(retorno.mensagens[0].descricao, "Sucesso", "success");
          } else {
            toast(retorno.mensagens[0].descricao, "Falha", "danger");
          }
          desativarShow("copiarAgenda");
        })
        .catch((error) => {
          desativarShow("copiarAgenda");
        });
    },
    excluirAgenda() {

      const { toast, ativarShow, desativarShow } = appUtils(this)

      this.$bvModal.hide("modal-conf-excluir-agenda");

      ativarShow("excluirAgenda");

      let api = this.$refs.calendarEscala.getApi()
      let dataAtual = api.getDate();

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

      let idsLocal = [];
      let idsProfissional = [];
      let idsTipo = [];
      let idsSituacao = [];
      if (this.itemLocal != null) {
        this.itemLocal.forEach((item) => {
          idsLocal.push(item.id);
        });
      }
      if (this.itemProfissional != null) {
        this.itemProfissional.forEach((item) => {
          idsProfissional.push(item.id);
        });
      }
      if (this.itemTipo != null) {
        this.itemTipo.forEach((item) => {
          idsTipo.push(item.id);
        });
      }
      if (this.itemSituacao != null) {
        this.itemSituacao.forEach((item) => {
          idsSituacao.push(item.id);
        });
      }

      let dadosEnvio =
        '{ "idUsuario": ' + dadosUsuario.idPessoa +
        ',"idsLocal": [' + idsLocal + "]" +
        ', "idsProfissional": [' + idsProfissional + "]" +
        ', "idsTipo": [' + idsTipo + "]" +
        ', "idsSituacao": [' + idsSituacao + "]" +
        ',"dtInicio": "' + dateToStr(dataAtual, "yyyy-MM-dd") + '"' +
        "}";

      AgendaService.excluirAgendas(this.http, dadosEnvio)
        .then((resposta) => {
          desativarShow("excluirAgenda");
          let retorno = resposta.data;
          if (retorno.status === "SUCCESS") {
            this.carregaDados();
            toast(retorno.mensagens[0].descricao, "Sucesso", "success");
          } else {
            toast(retorno.mensagens[0].descricao, "Falha", "danger");
          }
          desativarShow("excluirAgenda");
        })
        .catch((error) => {
          desativarShow("excluirAgenda");
        });
    },
    imprimirAgenda() {

      const { toast, ativarShow, desativarShow } = appUtils(this)

      ativarShow("imprimirAgenda");

      let api = this.$refs.calendarEscala.getApi()
      let dataAtual = api.getDate();

      let idsLocal = [];
      let idsProfissional = [];
      let idsTipo = [];
      let idsSituacao = [];
      if (this.itemLocal != null) {
        this.itemLocal.forEach((item) => {
          idsLocal.push(item.id);
        });
      }
      if (this.itemProfissional != null) {
        this.itemProfissional.forEach((item) => {
          idsProfissional.push(item.id);
        });
      }
      if (this.itemTipo != null) {
        this.itemTipo.forEach((item) => {
          idsTipo.push(item.id);
        });
      }
      if (this.itemSituacao != null) {
        this.itemSituacao.forEach((item) => {
          idsSituacao.push(item.id);
        });
      }

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

      let dadosEnvio = {
        "idUsuario": dadosUsuario.idPessoa,
        "coordenador": dadosUsuario.perfilCoordenador,
        "idEmpresa": dadosUsuario.grupo.empresa.id,
        "idsLocal": idsLocal,
        "idsProfissional": idsProfissional,
        "idsTipo": idsTipo,
        "idsSituacao": idsSituacao,
        "dtInicio": dateToStr(dataAtual, "yyyy-MM-dd")
        }

      AgendaService.imprimirAgenda(this.http, dadosEnvio)
        .then((resposta) => {
          desativarShow("imprimirAgenda");
          let retorno = resposta.data;
          if (retorno.status === "SUCCESS") {
            var base64 = retorno.obj;
            funcao.methods.downloadPdf(base64);
          } else {
            toast(retorno.mensagens[0].descricao, "Falha", "danger");
          }
          desativarShow("imprimirAgenda");
        })
        .catch((error) => {
          desativarShow("imprimirAgenda");
        });
    },
    exportarExcel() {

      const { toast, ativarShow, desativarShow } = appUtils(this)

      ativarShow("exportarExcel")

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

      let api = this.$refs.calendarEscala.getApi()
      let dataAtual = api.getDate();

      let idsLocal = [];
      let idsProfissional = [];
      let idsTipo = [];
      let idsSituacao = [];
      if (this.itemLocal != null) {
        this.itemLocal.forEach((item) => {
          idsLocal.push(item.id);
        });
      }
      if (this.itemProfissional != null) {
        this.itemProfissional.forEach((item) => {
          idsProfissional.push(item.id);
        });
      }
      if (this.itemTipo != null) {
        this.itemTipo.forEach((item) => {
          idsTipo.push(item.id);
        });
      }
      if (this.itemSituacao != null) {
        this.itemSituacao.forEach((item) => {
          idsSituacao.push(item.id);
        });
      }

      const mesAtual = dataAtual.getMonth() + 1;
      const anoAtual = dataAtual.getFullYear();

      let dadosEnvio = {
        "mes": mesAtual,
        "ano": anoAtual,
        "idUsuario": dadosUsuario.idPessoa,
        "idGrupoUsuario": dadosUsuario.grupo.id,
        "coordenador": dadosUsuario.perfilCoordenador,
        "idEmpresa": this.selectedFiltroApresentacao !== 'P' ? dadosUsuario.grupo.empresa.id : null,
        "idsLocal": idsLocal,
        "idsProfissional": idsProfissional,
        "idsTipo": idsTipo,
        "idsSituacao": idsSituacao
      }

      AgendaService.exportarAgendaExcel(this.http, dadosEnvio)
        .then((resposta) => {
          desativarShow("exportarExcel")
          let retorno = resposta.data;
          if (retorno.status === "SUCCESS") {
            var base64 = retorno.obj;
            funcao.methods.downloadExcel(base64);
          } else {
            toast(retorno.mensagens[0].descricao, "Falha", "danger");
          }
        })
        .catch((error) => {
          desativarShow("exportarExcel")
        });
    },
    handleClickCopiar(arg) {
      const { toast } = appUtils(this)
      toast("Copiar agenda do período anterior", "Sucesso", "success");
    },
    listaSituacoesFechamento() {
      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));
      let dadosConsulta =
        '{ "idUsuario": ' +
        dadosUsuario.idPessoa +
        ', "idLocal": ' +
        this.idLocalFechamento +
        "}";

      AgendaService.listaSituacoesFechamento(this.http, dadosConsulta)
        .then((resposta) => {
          this.itensSituacaoFechamento = resposta.data;
        })
        .catch((error) => { });
    },
    alterarSituacaoFechamento() {

      const { toast, ativarShow, desativarShow } = appUtils(this)

      ativarShow("alterarSituacaoFechamento");

      funcao.methods
        .alterarSituacaoFechamento(this.idFechamento, this.idSituacaoFechamento)
        .then((resposta) => {
          desativarShow("alterarSituacaoFechamento");
          let retorno = resposta.data;
          if (retorno.status === "SUCCESS") {
            toast(retorno.mensagens[0].descricao, "Sucesso", "success")
          } else {
            toast(retorno.mensagens[0].descricao, "Falha", "danger")
          }
        })
        .catch((error) => {
          desativarShow("alterarSituacaoFechamento");
        });
    },
    exportarHmtj() {

      const { toast, ativarShow, desativarShow } = appUtils(this)

      ativarShow("exportarHmtj");

      let ids = [this.idFechamento];
      funcao.methods
        .exportarHmtj(ids)
        .then((resposta) => {
          desativarShow("exportarHmtj");
          let retorno = resposta.data;
          if (retorno.status === "SUCCESS") {
            var base64 = retorno.obj;
            funcao.methods.downloadTxt(base64);
          } else {
            toast(retorno.mensagens[0].descricao, "Falha", "danger");
          }
        })
        .catch((error) => {
          desativarShow("exportarHmtj");
        });
    },
    hasAccess(menu) {
      return this.$store.getters['auth/hasAccess'](menu)
    },
    hasPermission(menu, permissao, nivel) {
      return this.$store.getters['auth/hasPermission'](menu, permissao, nivel)
    },
    hasPermissionWith(menu, permissao, nivel) {
      return this.$store.getters['auth/hasPermissionWith'](menu, permissao, nivel)
    },
    buscarTipo() {

      if (this.itensTipo.length === 0) {

        const { toast, ativarShow, desativarShow } = appUtils(this)

        ativarShow('buscarTipo')

        let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

        let idsLocal = [];
        if (this.itemLocal != null) {
          this.itemLocal.forEach((item) => {
            idsLocal.push(item.id);
          })
        }

        let dadosConsulta = {
          "idUsuario": dadosUsuario.idPessoa,
          "idsLocal": idsLocal
        }

        AgendaService.listaTiposFilter(dadosConsulta)
          .then((resposta) => {
            this.itensTipo = resposta.data.obj;
            desativarShow('buscarTipo')
          })
          .catch((e) => {
            desativarShow('buscarTipo')
            toast(e, "Falha", "danger");
          });
      }

    },
    exportarExcelConsolidado(op) {
      const { toast, ativarShow, desativarShow } = appUtils(this)

      this.$bvModal.hide("modal-conf-rel-fin")

      ativarShow("exportarExcelConsolidado")

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

      let api = this.$refs.calendarEscala.getApi()
      let dataAtual = api.getDate();

      let idsLocal = [];
      let idsProfissional = [];
      let idsTipo = [];
      let idsSituacao = [];
      if (this.itemLocal != null) {
        this.itemLocal.forEach((item) => {
          idsLocal.push(item.id);
        });
      }
      if (this.itemProfissional != null) {
        this.itemProfissional.forEach((item) => {
          idsProfissional.push(item.id);
        });
      }
      if (this.itemTipo != null) {
        this.itemTipo.forEach((item) => {
          idsTipo.push(item.id);
        });
      }
      if (this.itemSituacao != null) {
        this.itemSituacao.forEach((item) => {
          idsSituacao.push(item.id);
        });
      }

      const mesAtual = dataAtual.getMonth() + 1;
      const anoAtual = dataAtual.getFullYear();

      let dadosEnvio = {
        "idUsuario": dadosUsuario.idPessoa,
        "idEmpresa": this.selectedFiltroApresentacao !== 'P' ? dadosUsuario.grupo.empresa.id : null,
        "idsLocal": idsLocal,
        "coordenador": dadosUsuario.perfilCoordenador,
        "idsProfissional": idsProfissional,
        "idsTipo": idsTipo,
        "idsSituacao": idsSituacao,
        "mes": mesAtual,
        "ano": anoAtual,
        "comPrecoVenda": op.outrasColunasSelecionadas && op.outrasColunasSelecionadas.filter((p) => { return p === 'valorVenda' }).length == 1 ? 'S' : 'N',
        "comPrecoCompra": op.outrasColunasSelecionadas && op.outrasColunasSelecionadas.filter((p) => { return p === 'valorCompra' }).length == 1 ? 'S' : 'N',        
        "comTipoServico": op.outrasColunasSelecionadas && op.outrasColunasSelecionadas.filter((p) => { return p === 'tipoServico' }).length == 1 ? 'S' : 'N',
        "comTipoApuracao": op.outrasColunasSelecionadas && op.outrasColunasSelecionadas.filter((p) => { return p === 'tipoApuracao' }).length == 1 ? 'S' : 'N',
        "comPrecoUnitario": op.outrasColunasSelecionadas && op.outrasColunasSelecionadas.filter((p) => { return p === 'valorUnitario' }).length == 1 ? 'S' : 'N',
        "comRazaoPJ": op.outrasColunasSelecionadas && op.outrasColunasSelecionadas.filter((p) => { return p === 'razaoPJ' }).length == 1 ? 'S' : 'N'
      }

      AgendaService.exportarRelatorioFinanceiroConsolidado(dadosEnvio)
        .then((resposta) => {
          desativarShow("exportarExcelConsolidado")
          let retorno = resposta.data;
          if (retorno.status === "SUCCESS") {
            var base64 = retorno.obj;
            funcao.methods.downloadExcel(base64);
          } else {
            toast(retorno.mensagens[0].descricao, "Falha", "danger");
          }
        })
        .catch((error) => {
          desativarShow("exportarExcelConsolidado")
        });
    },
    exportarExcelDetalhado(op) {
      const { toast, ativarShow, desativarShow } = appUtils(this)

      ativarShow("exportarExcelDetalhado")

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

      let api = this.$refs.calendarEscala.getApi()
      let dataAtual = api.getDate();

      let idsLocal = [];
      let idsProfissional = [];
      let idsTipo = [];
      let idsSituacao = [];
      if (this.itemLocal != null) {
        this.itemLocal.forEach((item) => {
          idsLocal.push(item.id);
        });
      }
      if (this.itemProfissional != null) {
        this.itemProfissional.forEach((item) => {
          idsProfissional.push(item.id);
        });
      }
      if (this.itemTipo != null) {
        this.itemTipo.forEach((item) => {
          idsTipo.push(item.id);
        });
      }
      if (this.itemSituacao != null) {
        this.itemSituacao.forEach((item) => {
          idsSituacao.push(item.id);
        });
      }

      const mesAtual = dataAtual.getMonth() + 1;
      const anoAtual = dataAtual.getFullYear();

      let dadosEnvio = {
        "idUsuario": dadosUsuario.idPessoa,
        "idEmpresa": this.selectedFiltroApresentacao !== 'P' ? dadosUsuario.grupo.empresa.id : null,
        "coordenador": dadosUsuario.perfilCoordenador,
        "idsLocal": idsLocal,
        "idsProfissional": idsProfissional,
        "idsTipo": idsTipo,
        "idsSituacao": idsSituacao,
        "mes": mesAtual,
        "ano": anoAtual,
        "comPrecoVenda": op && op.outrasColunasSelecionadas && op.outrasColunasSelecionadas.filter((p) => { return p === 'valorVenda' }).length == 1 ? 'S' : 'N',
        "comPrecoCompra": op && op.outrasColunasSelecionadas && op.outrasColunasSelecionadas.filter((p) => { return p === 'valorCompra' }).length == 1 ? 'S' : 'N',        
        "comTipoServico": op && op.outrasColunasSelecionadas && op.outrasColunasSelecionadas.filter((p) => { return p === 'tipoServico' }).length == 1 ? 'S' : 'N',
        "comPrecoUnitario": op && op.outrasColunasSelecionadas && op.outrasColunasSelecionadas.filter((p) => { return p === 'valorUnitario' }).length == 1 ? 'S' : 'N'
      }

      AgendaService.exportarRelatorioFinanceiroDetalhado(dadosEnvio)
        .then((resposta) => {
          desativarShow("exportarExcelDetalhado")
          let retorno = resposta.data;
          if (retorno.status === "SUCCESS") {
            var base64 = retorno.obj;
            funcao.methods.downloadExcel(base64);
          } else {
            toast(retorno.mensagens[0].descricao, "Falha", "danger");
          }
        })
        .catch((error) => {
          desativarShow("exportarExcelDetalhado")
        });
    },
    exportarRelatorioFolhaPonto() {
      const { toast, ativarShow, desativarShow } = appUtils(this)

      ativarShow("exportarRelatorioFolhaPonto")

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

      let api = this.$refs.calendarEscala.getApi()
      let dataAtual = api.getDate();

      let idsLocal = [];
      let idsProfissional = [];
      let idsTipo = [];
      let idsSituacao = [];
      if (this.itemLocal != null) {
        this.itemLocal.forEach((item) => {
          idsLocal.push(item.id);
        });
      }
      if (this.itemProfissional != null) {
        this.itemProfissional.forEach((item) => {
          idsProfissional.push(item.id);
        });
      }
      if (this.itemTipo != null) {
        this.itemTipo.forEach((item) => {
          idsTipo.push(item.id);
        });
      }
      if (this.itemSituacao != null) {
        this.itemSituacao.forEach((item) => {
          idsSituacao.push(item.id);
        });
      }

      const mesAtual = dataAtual.getMonth() + 1;
      const anoAtual = dataAtual.getFullYear();

      let dadosEnvio = {
        "idUsuario": dadosUsuario.idPessoa,
        "idEmpresa": this.selectedFiltroApresentacao !== 'P' ? dadosUsuario.grupo.empresa.id : null,
        "idsLocal": idsLocal,
        "coordenador": dadosUsuario.perfilCoordenador,
        "idsProfissional": idsProfissional,
        "idsTipo": idsTipo,
        "idsSituacao": idsSituacao,
        "mes": mesAtual,
        "ano": anoAtual
      }

      AgendaService.exportarRelatorioFolhaPonto(dadosEnvio)
        .then((resposta) => {
          desativarShow("exportarRelatorioFolhaPonto")
          let retorno = resposta.data;
          if (retorno.status === "SUCCESS") {
            var base64 = retorno.obj;
            funcao.methods.downloadExcel(base64);
          } else {
            toast(retorno.mensagens[0].descricao, "Falha", "danger");
          }
        })
        .catch((error) => {
          desativarShow("exportarRelatorioFolhaPonto")
        });
    },
    confirmaRelatorioConsolidado() {
      let colunas = []
      if (this.exibirValorVenda) colunas.push({ text: 'Valor de venda', value: 'valorVenda' })
      if (this.exibirValorCompra) colunas.push({ text: 'Valor de compra', value: 'valorCompra' })
      colunas.push({ text: 'Tipo de serviço', value: 'tipoServico' })      
      colunas.push({ text: 'Tipo de apuração', value: 'tipoApuracao' })
      if (this.exibirValorVenda || this.exibirValorCompra) colunas.push({ text: 'Valor unitário', value: 'valorUnitario' })
      
      colunas.push({ text: 'PJ', value: 'razaoPJ' })

      this.$refs.confRelFinConsolidado.show(colunas)
    },
    confirmaRelatorioDetalhado() {

      let colunas = []
      if (this.exibirValorVenda) colunas.push({ text: 'Valor de venda', value: 'valorVenda' })
      if (this.exibirValorCompra) colunas.push({ text: 'Valor de compra', value: 'valorCompra' })

      if (colunas.length > 0) {        
        this.$refs.confRelFinDetalhado.show(colunas)
      } else {
        this.exportarExcelDetalhado()
      }

    },
    confirmaRelatorioPersonalizado(id) {

      const { toast, ativarShow, desativarShow } = appUtils(this)

      ativarShow("confirmaRelatorioPersonalizado")

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

      let api = this.$refs.calendarEscala.getApi()
      let dataAtual = api.getDate();

      let idsLocal = [];
      let idsProfissional = [];
      let idsTipo = [];
      let idsSituacao = [];
      if (this.itemLocal != null) {
        this.itemLocal.forEach((item) => {
          idsLocal.push(item.id);
        });
      }
      if (this.itemProfissional != null) {
        this.itemProfissional.forEach((item) => {
          idsProfissional.push(item.id);
        });
      }
      if (this.itemTipo != null) {
        this.itemTipo.forEach((item) => {
          idsTipo.push(item.id);
        });
      }
      if (this.itemSituacao != null) {
        this.itemSituacao.forEach((item) => {
          idsSituacao.push(item.id);
        });
      }

      const mesAtual = dataAtual.getMonth() + 1;
      const anoAtual = dataAtual.getFullYear();

      let dadosEnvio = {
        "id": id,
        "idUsuario": dadosUsuario.idPessoa,
        "idEmpresa": this.selectedFiltroApresentacao !== 'P' ? dadosUsuario.grupo.empresa.id : null,
        "coordenador": dadosUsuario.perfilCoordenador,
        "idsLocal": idsLocal,
        "idsProfissional": idsProfissional,
        "idsTipo": idsTipo,
        "idsSituacao": idsSituacao,
        "mes": mesAtual,
        "ano": anoAtual,
        "comPrecoVenda": 'S',
        "comPrecoCompra": 'S',        
        "comTipoServico": 'S',
        "comPrecoUnitario": 'S'
      }

      AgendaService.exportarRelatorioPersonalizado(dadosEnvio)
        .then((resposta) => {
          desativarShow("confirmaRelatorioPersonalizado")
          let retorno = resposta.data;
          if (retorno.status === "SUCCESS") {
            var base64 = retorno.obj;
            funcao.methods.downloadExcel(base64);
          } else {
            toast(retorno.mensagens[0].descricao, "Falha", "danger");
          }
        })
        .catch((error) => {
          desativarShow("confirmaRelatorioPersonalizado")
        });


    },
    confirmaRelatorioCalendario() {
      let colunas = []
      colunas.push({ text: 'Local', value: 'local' })
      colunas.push({ text: 'Tipo', value: 'tipo' })
      colunas.push({ text: 'Horário', value: 'horario' })
      colunas.push({ text: 'CRM', value: 'crm' })
      this.$refs.confRelCalendario.show(colunas)      
    },
    exportarExcelCalendario(op) {
      const { toast, ativarShow, desativarShow } = appUtils(this)

      ativarShow("exportarExcelCalendario")

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

      let api = this.$refs.calendarEscala.getApi()
      let dataAtual = api.getDate();

      let idsLocal = [];
      let idsProfissional = [];
      let idsTipo = [];
      let idsSituacao = [];
      if (this.itemLocal != null) {
        this.itemLocal.forEach((item) => {
          idsLocal.push(item.id);
        });
      }
      if (this.itemProfissional != null) {
        this.itemProfissional.forEach((item) => {
          idsProfissional.push(item.id);
        });
      }
      if (this.itemTipo != null) {
        this.itemTipo.forEach((item) => {
          idsTipo.push(item.id);
        });
      }
      if (this.itemSituacao != null) {
        this.itemSituacao.forEach((item) => {
          idsSituacao.push(item.id);
        });
      }

      const mesAtual = dataAtual.getMonth() + 1;
      const anoAtual = dataAtual.getFullYear();

      let dadosEnvio = {
        "idUsuario": dadosUsuario.idPessoa,
        "idEmpresa": this.selectedFiltroApresentacao !== 'P' ? dadosUsuario.grupo.empresa.id : null,
        "coordenador": dadosUsuario.perfilCoordenador,
        "idsLocal": idsLocal,
        "idsProfissional": idsProfissional,
        "idsTipo": idsTipo,
        "idsSituacao": idsSituacao,
        "mes": mesAtual,
        "ano": anoAtual,
        "comLocal": op && op.outrasColunasSelecionadas && op.outrasColunasSelecionadas.filter((p) => { return p === 'local' }).length == 1 ? 'S' : 'N',
        "comTipo": op && op.outrasColunasSelecionadas && op.outrasColunasSelecionadas.filter((p) => { return p === 'tipo' }).length == 1 ? 'S' : 'N',
        "comHorario": op && op.outrasColunasSelecionadas && op.outrasColunasSelecionadas.filter((p) => { return p === 'horario' }).length == 1 ? 'S' : 'N',
        "comCrm": op && op.outrasColunasSelecionadas && op.outrasColunasSelecionadas.filter((p) => { return p === 'crm' }).length == 1 ? 'S' : 'N',
      }

      AgendaService.exportarRelatorioCalendario(dadosEnvio)
        .then((resposta) => {
          desativarShow("exportarExcelCalendario")
          let retorno = resposta.data;          
          if (retorno.status === "SUCCESS") {
            var base64 = retorno.obj;
            funcao.methods.downloadExcel(base64);
          } else {
            toast(retorno.mensagens[0].descricao, "Falha", "danger");
          }
        })
        .catch((error) => {
          desativarShow("exportarExcelCalendario")
        });
    },
    abrirMenuPlantao(e, arg) {      
      this.argAtual = arg
      this.acaoEvento = true
      this.$refs.menu.open(e);
    },
    buscarRelatorios() {

      const { toast } = appUtils(this)

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

      let dadosConsulta = {
        "idEmpresa": dadosUsuario.grupo.empresa.id
      }

      AgendaService.buscarRelatoriosAgenda(dadosConsulta)
        .then((resposta) => {
          let retorno = resposta.data;
          if (retorno.status === "SUCCESS") {
            this.relatorios = retorno.obj
          } else {
            toast(retorno.mensagens[0].descricao, "Falha", "danger");
          }
        })
        .catch((e) => { });
    },
    /*onSearchProfissional(search, loading) {
      if (search.length) {
        loading(true);
        this.search(loading, search, this);
      }
    },
    search: _.debounce((loading, search, vm) => {

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

      let idsLocal = [];
      if (vm.itemLocal != null) {
        vm.itemLocal.forEach((item) => {
          idsLocal.push(item.id);
        });
      }

      let dadosConsulta = {
        "idUsuario": dadosUsuario.idPessoa,
        "idsLocal": idsLocal,
        "filtro": search,
        "idEmpresa": dadosUsuario.grupo.empresa.id
      }

      AgendaService.listaProfissionaisFilter(vm.http, dadosConsulta)
        .then((resposta) => {

          vm.itensProfissionais = resposta.data.obj;
          vm.itensProfissionais.push({ id: 0, nome: '[Sem profissional]', pnc: 'N' })

          loading(false);
        })
        .catch(() => {
          loading(false);
        });

    }, 350),
    onSearchProfissionalPnc(search, loading) {
      if (search.length) {
        loading(true);
        this.searchPnc(loading, search, this);
      }
    },
    searchPnc: _.debounce((loading, search, vm) => {

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

      let dadosConsulta = {
        "idUsuario": dadosUsuario.idPessoa,
        "filtro": search,
        "idEmpresa": dadosUsuario.grupo.empresa.id
      }

      AgendaService.listaProfissionaisFilter(vm.http, dadosConsulta)
        .then((resposta) => {
          vm.listaProfissionaisSearch = resposta.data.obj
          loading(false);
        })
        .catch(() => {
          loading(false);
        });

    }, 350)*/
  }

};

function dateToStr(date, format) {
  if (date !== undefined && date != null) {
    let strDate = format
      .replace("yyyy", date.getFullYear())
      .replace("MM", (date.getMonth() + 1).toString().padStart(2, "0"))
      .replace("dd", date.getDate().toString().padStart(2, "0"))
      .replace("HH", date.getHours().toString().padStart(2, "0"))
      .replace("mm", date.getMinutes().toString().padStart(2, "0"))
      .replace("ss", date.getSeconds().toString().padStart(2, "0"));
    return strDate;
  }
}

const maskPhone = (value) => {
  return value
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d)/, "($1) $2")
    .replace(/(\d{5})(\d)/, "$1-$2")
    .replace(/(-\d{4})(\d+?)$/, "$1");
};

</script>

<style lang="scss">
//  se for adicionar linhas na grade, colocar .fc th
.fc td {
  border-style: none !important;
}

.fc .fc-daygrid-day-top {
  margin: 0 .2em;
  background-color: #d3d3d3;
}

.fc-daygrid-day-number {
  font-size: 10px;
  color: #000;
}

.fc-h-event {
  border: 1px solid #d3d3d3 !important;
  background-color: #d3d3d3 !important;
}

.fc-daygrid-day-events {
  border: none !important;
}

.fc .fc-daygrid-event-harness {
  margin: 0 .05em;

}

.copiarOff .demo-app-calendar .fc-customButtonCopiar-button {
  display: none;
}

.excluirOff .demo-app-calendar .fc-customButtonExcluir-button {
  display: none;
}

.imprimirOff .demo-app-calendar .fc-customButtonImprimir-button {
  display: none;
}

.agenda {

  .list-group-item {
    padding: 0.15rem 0.45rem;
  }

  .menu-plantao {
    font-size: 0.75rem;
  }

  .menu-plantao .show {
    z-index: 9999;
  }

  .card .card-body {
    padding-bottom: 0;
    padding-top: 0;
  }

  .fc .fc-toolbar.fc-header-toolbar {
    margin-top: 3px;
    margin-bottom: 3px;
  }

  .fc-day-sun .fc-col-header-cell-cushion,
  .fc-day-sat .fc-col-header-cell-cushion {
    color: #888888;
  }

  .elipse-js {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .dayspan {
    font-family: Roboto, sans-serif;
    width: 100%;
    height: 100%;
  }

  .tipoHorario {
    border-bottom: 3px solid rgb(146, 38, 123);
    font-size: 12px;
  }

  .centro {
    border-right: 1px solid #ccc;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    background-color: #fff;
    color: rgb(99, 96, 96);
  }

  .bottom {
    background-color: chocolate;
    width: 100%;
    height: 5px;
  }

  .fc .fc-daygrid-day-frame {
    position: relative;
  }

  .brSize {
    font-size: 6px;
  }

  .botaoadd {
    border: 1px solid #ecf0f1;
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
    font-size: 10px;
    color: #7f8c8d;
    width: 100%;
  }

  .card .table tbody td:last-child,
  .card .table thead th:last-child {
    padding-right: 15px;
    height: 100%;
    display: none;
  }

  .filtro {
    font-size: 0.7rem;
  }

  .troca:before {
    display: inline-block;
    content: ' ';
    border-color: transparent #3472f7 transparent transparent;
    border-style: solid;
    border-width: 0 7px 7px 0;
    height: 0;
    width: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  .prof-nao-alocado:before {
    display: inline-block;
    content: ' ';
    border-color: transparent #ff9500 transparent transparent;
    border-style: solid;
    border-width: 0 7px 7px 0;
    height: 0;
    width: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  .horario label,
  .duracao label {
    font-weight: bold;
    font-size: 10px;
    color: #adadad;
  }

  .duracao label {
    font-weight: bold;
    font-size: 10px;
    color: #696969;
  }

}

@media (max-width: 1150px) {
  .detalhe-agenda {
    .btn-busca.btn-sm {
      padding-right: 7px;
    }
  }
}

@media (max-width: 768px) {

  .demo-app-calendar .row {
    font-size: 5px;
  }

  .demo-app-calendar .titulo {
    font-size: 5px;
  }

  .demo-app-calendar .horario label,
  .demo-app-calendar .duracao label {
    font-size: 5px;
  }

  /*fc-toolbar-title*/
  .agenda {
    font-size: 0.5rem;
  }

  .agenda .filtro {
    padding-top: 5px;
  }

  .demo-app-calendar .fc-customButtonCopiar-button,
  .demo-app-calendar .fc-customButtonImprimir-button,
  .demo-app-calendar .fc-customButtonExcluir-button {
    display: none;
  }

  /* Edição da agenda */

  .detalhe-agenda row {
    font-size: 0.5rem;
  }

  .detalhe-agenda .edit-agenda {
    font-size: 0.5rem;
  }

}

:root {
  --fc-button-bg-color: #3472f7;
  --fc-button-active-bg-color: #6c757d;
  --fc-button-active-border-color: #6c757d;
  --fc-button-border-color: #3472f7;
  --fc-button-hover-bg-color: #3472f796;
  --fc-button-hover-border-color: #3472f796;
}

/* Detalhes da agenda */

.detalhe-agenda {

  .row,
  select,
  input,
  .form-control,
  .input-group-prepend>.input-group-text,
  .input-group-append>.input-group-text {
    font-size: 12px;
    font-weight: bold;
  }

  .v-select,
  select,
  input,
  .input-group-prepend>.input-group-text,
  .input-group-append>.input-group-text {
    background-color: white;
  }

  .input-group-prepend>.input-group-text {
    padding-top: 0;
    padding-bottom: 0;
  }

  .form-control {
    height: 25px;
  }

  .btn-busca {
    font-size: 8px;
  }

  .data-livre {
    font-size: 9.5px;
    margin-top: 1px;
    margin-bottom: 1px;
  }

  .tab-detalhe-agenda {
    .nav-link {
      font-size: 14px;
      padding-top: 2px;
      padding-bottom: 2px;
    }
  }

  .box-btn-acao {
    padding-top: 5px;
    padding-bottom: 5px;
  }

}</style>