<template>
  <div class="tela-fechamentos">

    <b-modal ref="modal-copiar" id="modal-copiar" size="lg" title="Cópia de escala" hide-footer>
      <div class="container-fluid">
        <b-row class="my-1">
          <div class="col-md-12"></div>
        </b-row>
      </div>
    </b-modal>

    <b-modal ref="modal-teste" id="modal-teste" size="lg" title="teste" hide-footer>
      <div class="container-fluid">
        <b-row class="my-1">
          <div class="col-md-12">
            teste
          </div>
        </b-row>
      </div>
    </b-modal>

    <b-modal ref="modal-conf-canc-fecha" id="modal-conf-excl-fecha" size="lg" title="Confirmação" hide-footer>
      <div class="container-fluid">
        <b-row class="my-1">
          <div class="col-md-12">
            {{ msgConfirma }}
          </div>
        </b-row>
        <b-row class="my-1">
          <div class="col-md-12">
            <button size="sm" style="margin-left: 4px" class="btn btn-danger btn-fill float-right"
              @click="$bvModal.hide('modal-conf-excl-fecha')">
              Não
            </button>
            <button size="sm" class="btn btn-success btn-fill float-right" @click="cancelarFechamento">
              Sim
            </button>
          </div>
        </b-row>
      </div>
    </b-modal>

    <b-modal ref="modal-historico" id="modal-historico" size="lg" title="Histórico" hide-footer
      header-bg-variant="secondary" header-text-variant="light">
      <div class="container-fluid">
        <b-row class="my-1">
          <div class="col-md-12">

            <b-table :fixed="true" :bordered="true" :items="historicos" :fields="fieldsHistorico" responsive="sm"
              head-variant="light" sticky-header stacked="md">

              <template v-for="field in fieldsHistorico" v-slot:[`cell(${field.key})`]="{ value, item, field }">
                <template v-if="field.type !== undefined && field.type === 'date'">{{ strDateFormat(value, field.format)}}</template>
                <template v-else>{{ value }}</template>
              </template>
              
            </b-table>
          </div>
        </b-row>
      </div>
    </b-modal>

    <confirma-relatorio-financeiro id="consolidado" ref="confRelFinConsolidado" @executou="(r) => { if(r.status === 'success') exportarExcelConsolidado(r.escolha) }"/>
    <confirma-relatorio-financeiro id="detalhado" ref="confRelFinDetalhado" @executou="(r) => { if(r.status === 'success') exportarExcelDetalhado(r.escolha) }"/>

    <card>

      <template slot="header">
        <h4 class="card-title">Fechamentos</h4>
      </template>


      <div class="container-fluid c-form">

        <div class="row">
          <div class="col-md-4">

            <filtro-local :itemLocal="itemLocal" @update="itemLocal = $event;" ></filtro-local>

          </div>

          <div class="col-md-4">
            <b-input-group>

              <b-button variant="primary" size="sm" class="mb-2 mr-1" @click="goMesAnterior">
                <b-icon icon="arrow-left-circle"></b-icon>
              </b-button>

              <b-form-datepicker id="dtInicio" v-model="dtInicio" size="sm" class="mb-2" placeholder="Início do período"
                today-button reset-button close-button today-button-variant="primary" reset-button-variant="danger"
                close-button-variant="success" label-no-date-selected="Nenhuma data selecionada"
                label-today-button="Hoje" label-reset-button="Limpar" label-close-button="Fechar" 
                :date-format-options="{year: 'numeric', month: 'numeric', day: 'numeric'}">
              </b-form-datepicker>
              <b-input-group-text class="mb-2">até</b-input-group-text>
              <b-form-datepicker id="dtFim" v-model="dtFim" size="sm" class="mb-2" placeholder="Início do período"
                today-button reset-button close-button today-button-variant="primary" reset-button-variant="danger"
                close-button-variant="success" label-no-date-selected="Nenhuma data selecionada"
                label-today-button="Hoje" label-reset-button="Limpar" label-close-button="Fechar" 
                :date-format-options="{year: 'numeric', month: 'numeric', day: 'numeric'}">
              </b-form-datepicker>
              <b-button variant="primary" size="sm" class="mb-2 ml-1" @click="goMesSeguinte">
                <b-icon icon="arrow-right-circle"></b-icon>
              </b-button>
            </b-input-group>
          </div>


          <div class="col-md-4">
            <button type="button" style="margin-left: 4px" class="btn btn-primary btn-fill btn-sm float-left"
              @click="pesquisar">
              Pesquisar
            </button>
          </div>
        </div>

        <div class="row">
          <div class="col-12">

            <b-table id="tbl-fechamento" :fixed="true" :bordered="false" striped hover :items="dadosRelatorio.lista"
              tbody-tr-class="grid-tr" class="grid-js" head-variant="light"
              :fields="fields" responsive="true" stacked="md" ref="tbLista">

              <template #head(selecione)>
                <b-form-checkbox v-model="todosSelecionados" @input="selecionarTodos"></b-form-checkbox>
              </template>

              <template v-slot:cell(selecione)="row">
                <b-form-checkbox v-model="row.item.selecione"></b-form-checkbox>
              </template>

              <template v-slot:cell(dtIni)="row">
                {{ strDateFormat(row.item.dtIni, 'dd/MM/yyyy') }}
              </template>

              <template v-slot:cell(dtFim)="row">
                {{ strDateFormat(row.item.dtFim, 'dd/MM/yyyy') }}
              </template>

              <template v-slot:cell(dtFecha)="row">
                {{ strDateFormat(row.item.dtFecha, 'dd/MM/yyyy') }}
              </template>

              <template #cell()="data">
                {{ data.value }}
              </template>

              <template v-slot:cell(dscSituacao)="row">
                <b-form-select v-if="editingRow && editingRow.id === row.item.id" v-model="editingRow.idSituacao"
                  :options="itensSituacaoEdit" value-field="id" text-field="descricao" size="sm">
                </b-form-select>
                <span v-else>{{ row.value }}</span>
              </template>

              <template v-slot:cell(actions)="row">
                <b-dropdown size="sm" v-if="!editingRow" variant="warning" class="mr-1">
                  <b-dropdown-item-button v-if="hasPermission('FECHAMENTOS', 'EXCLUIR', 'E')"
                    @click="confirmaCancelaFechamento(row.item)">Excluir</b-dropdown-item-button>
                  <b-dropdown-item-button v-if="hasPermission('FECHAMENTOS', 'HISTORICO', 'E')"
                    @click="abrirHistorico(row.item)">Histórico</b-dropdown-item-button>
                  <b-dropdown-item-button v-if="hasPermission('FECHAMENTOS', 'EXP_JS', 'E')"
                    @click="exportarJSoftware(row.item)">Exportar JSoftware</b-dropdown-item-button>
                  <b-dropdown-item-button v-if="hasPermission('FECHAMENTOS', 'EXP_HMTJ', 'E')"
                    @click="exportarHmtj(row.item, true)">Exportar HMTJ</b-dropdown-item-button>
                  <b-dropdown-item-button v-if="hasPermission('FECHAMENTOS', 'EXP_EXCEL', 'E')"
                    @click="exportarExcel(row.item)">Exportar Excel</b-dropdown-item-button>                  
                    <b-dropdown-item-button v-if="false"
                    @click="exportarPlanilha(row.item)">Planilha</b-dropdown-item-button>

                  <b-dropdown-item-button @click="confirmaRelatorioConsolidado(row.item)">Relatório consolidado</b-dropdown-item-button>
                  <b-dropdown-item-button @click="confirmaRelatorioDetalhado(row.item)">Relatório detalhado</b-dropdown-item-button>                    

                  <template #button-content>
                    <b-icon icon="menu-button-wide" aria-hidden="true"></b-icon>
                  </template>
                </b-dropdown>

                <b-button-group v-if="editingRow && editingRow.id === row.item.id">
                  <b-button size="sm" variant="success" @click="saveEdit" title="Confirmar alteração">
                    <b-icon icon="check-circle" aria-hidden="true"></b-icon>
                  </b-button>
                  <b-button size="sm" variant="danger" class="ml-1" @click="resetEdit" title="Cancelar edição">
                    <b-icon icon="x-circle" aria-hidden="true"></b-icon>
                  </b-button>
                </b-button-group>

                <b-button-group class="mr-1" v-else>
                  <b-button size="sm" variant="info" v-if="hasPermission('FECHAMENTOS', 'ABRIR_FECHAMENTO', 'E')"
                    @click="abrirAgenda(row.item)" title="Abrir agendas do fechamento">
                    <b-icon icon="list-task" aria-hidden="true"></b-icon>
                  </b-button>
                  <b-button size="sm" variant="primary" v-if="hasPermission('FECHAMENTOS', 'EDITAR', 'E')" class="ml-1"
                    @click="edit(row.item)" title="Alterar situação">
                    <b-icon icon="pencil-square" aria-hidden="true"></b-icon>
                  </b-button>
                </b-button-group>

              </template>
            </b-table>
          </div>
        </div>
        <div v-if="dadosRelatorio.quantidade !== undefined" class="row">
          <div class="col-2">
            <p>Quantidade: {{ dadosRelatorio.quantidade }}</p>
          </div>
        </div>
      </div>
    </card>
  </div>
</template>

<script>
import LTable from "src/components/Table.vue";
import Card from "src/components/Cards/Card.vue";
import axios from "axios";
import { BASE_CONSTANTS } from "../service/config";
import AgendaService from "../service/AgendaService";
import moment from "moment";
import funcao from "src/components/Funcao";
import appUtils from '@/composables/utils'
import FiltroLocal from './comuns/FiltroLocal'
import ConfirmaRelatorioFinanceiro from './comuns/ConfirmaRelatorioFinanceiro.vue'

export default {
  components: {
    LTable,
    Card,
    moment,
    FiltroLocal,
    ConfirmaRelatorioFinanceiro
  },
  data() {
    return {
      survey: null,
      http: null,
      msg: null,
      show: false,
      showFrom: "",
      filaShow: [],
      itensLocal: [],
      itemLocal: null,
      dtInicio: null,
      dtFim: null,
      fields: [],
      dadosRelatorio: [],
      showConfirma: false,
      msgConfirma: null,
      perPage: 10,
      currentPage: 1,
      selected: [],
      editingRow: null,
      deletingRow: null,
      itensSituacaoEdit: [],
      fieldsHistorico: [],
      historicos: [],
      todosSelecionados: 'N',
      comPrecoVenda: "N",
      comPrecoCompra: "N",
      entity: null,
    };
  },
  mounted() {

    const { ativarShow, desativarShow } = appUtils(this)

    const token = String(localStorage.getItem("authorization")).replaceAll(
      '"',
      ""
    );

    if (
      token === null ||
      token === undefined ||
      token === "" ||
      token === "null"
    ) {
      this.$route.router.go("/login");
    } else {
      axios
        .get(BASE_CONSTANTS.urlBase + "oauth/check_token/?token=" + token)
        .then((resposta) => {
          this.carregaDados();
        })
        .catch((error) => {
          if (error === false) {
            this.$router.push("/login");
          }
        });
    }

    this.fields = [
      { label: "", key: "selecione", thStyle: { width: "3%" } },
      { label: "Local", key: "dscLocalCompleto", sortable: true, thStyle: { width: "26%" }, tdClass: "fonte-local" },
      { label: "Período", key: "dtIni", sortable: true, thStyle: { width: "7%" } },
      { label: "Até", key: "dtFim", sortable: true, thStyle: { width: "7%" } },
      { label: "Data Aprovação", key: "dtFecha", sortable: true, thStyle: { width: "8%" } },
      { label: "Usuário", key: "nomeUsuario", sortable: true, thStyle: { width: "10%" } },
      { label: "Situação", key: "dscSituacao", sortable: true, editable: true, thStyle: { width: "15%" } },
      { label: "Ação", key: "actions", class: "text-center", thStyle: { width: "22%" } },
    ];

    this.fieldsHistorico = [
      { label: "Descrição", key: "descricao", thStyle: { width: "45%" } },
      {
        label: "Sit. anterior",
        key: "dscSituacaoAnterior",
        thStyle: { width: "15%" },
      },
      { label: "Situação", key: "dscSituacao", thStyle: { width: "15%" } },
      {
        label: "Data",
        key: "data",
        type: "date",
        format: "dd/MM/yyyy HH:mm:ss",
        thStyle: { width: "10%" },
      },
      { label: "Responsável", key: "dscUsuario", thStyle: { width: "15%" } },
    ];

    this.dadosRelatorio = [];
    this.http = axios.create({
      baseURL: BASE_CONSTANTS.urlBase,
      headers: {
        Authorization:
          "bearer " +
          String(localStorage.getItem("authorization")).replaceAll('"', ""),
        "Content-Type": "application/json",
        Accept: "*/*",
      },
    });

    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    this.dtInicio = dateToStr(firstDay, "yyyy-MM-dd");
    this.dtFim = dateToStr(lastDay, "yyyy-MM-dd");

    this.exibirValorVenda = this.hasPermission('RELATORIO_FINANCEIRO', 'PRECO_VENDA', 'E')
    this.exibirValorCompra = this.hasPermission('RELATORIO_FINANCEIRO', 'PRECO_COMPRA', 'E')

  },
  methods: {
    goMesAnterior() {
      const dataAtual = new Date(this.dtInicio+"T00:00:00")
      dataAtual.setMonth(dataAtual.getMonth()-1);
      var firstDay = new Date(dataAtual.getFullYear(), dataAtual.getMonth(), 1)
      var lastDay = new Date(dataAtual.getFullYear(), dataAtual.getMonth() + 1, 0)
      this.dtInicio = dateToStr(firstDay, "yyyy-MM-dd")
      this.dtFim = dateToStr(lastDay, "yyyy-MM-dd")
    },
    goMesSeguinte() {      
      const dataAtual = new Date(this.dtInicio+"T00:00:00")
      dataAtual.setMonth(dataAtual.getMonth()+1);            
      var firstDay = new Date(dataAtual.getFullYear(), dataAtual.getMonth(), 1)
      var lastDay = new Date(dataAtual.getFullYear(), dataAtual.getMonth() + 1, 0)
      this.dtInicio = dateToStr(firstDay, "yyyy-MM-dd")
      this.dtFim = dateToStr(lastDay, "yyyy-MM-dd")
    },
    pesquisar() {

      const { toast, ativarShow, desativarShow } = appUtils(this)

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

      this.dadosRelatorio = [];

      ativarShow("pesquisar");

      let idsLocal = [];
      if (this.itemLocal != null) {
        idsLocal.push(this.itemLocal.id);
      }

      let filter = {
        "idUsuario": dadosUsuario.idPessoa,
        "idTabela": dadosUsuario.grupo.idTabela,
        "idsLocal": idsLocal,
        "dtIni": this.dtInicio,
        "dtFim": this.dtFim
      }

      AgendaService.listarFechamento(this.http, filter)
        .then((resposta) => {
          desativarShow("pesquisar");
          let retorno = resposta.data;
          if (retorno.status === "SUCCESS") {
            this.dadosRelatorio = retorno.obj;
          } else {
            toast(retorno.mensagens[0].descricao, "Falha", "danger");
          }
        })
        .catch((error) => {
          desativarShow("pesquisar");
        });
    },
    listarSituacoes() {
      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));
      let dadosConsulta =
        '{ "idUsuario": ' +
        dadosUsuario.idPessoa +
        ', "idLocal": ' +
        this.editingRow.idLocal +
        "}";

      AgendaService.listaSituacoesFechamento(this.http, dadosConsulta)
        .then((resposta) => {
          this.itensSituacaoEdit = resposta.data;
        })
        .catch((error) => { });
    },
    toast(toaster, append = false, mensagem, cabecalho, tipoMensagem) {
      this.counter++;
      this.$bvToast.toast(mensagem, {
        title: cabecalho,
        toaster: toaster,
        solid: true,
        variant: tipoMensagem,
        appendToast: append,
      });
    },
    selectAllRows() {
      this.$refs.tbLista.selectAllRows();
    },
    clearSelected() {
      this.$refs.tbLista.clearSelected();
    },
    onRowSelected(items) {
      this.selected = items;
    },
    edit(entity) {
      let doEdit = true;
      if (
        this.editingRow &&
        !confirm("Existe registro que ainda não foi gravado, deseja continuar?")
      ) {
        doEdit = false;
      }
      if (doEdit) {
        this.editingRow = { ...entity };
        this.listarSituacoes();
      }
    },

    async saveEdit() {
      const { toast, ativarShow, desativarShow } = appUtils(this)

      ativarShow("saveEdit")

      funcao.methods
        .alterarSituacaoFechamento(
          this.editingRow.id,
          this.editingRow.idSituacao
        )
        .then((resposta) => {
          desativarShow("saveEdit")
          let retorno = resposta.data;
          if (retorno.status === "SUCCESS") {
            this.dadosRelatorio.comFechamento = true;

            let index = this.dadosRelatorio.lista.indexOf(
              this.dadosRelatorio.lista.find((l) => l.id === this.editingRow.id)
            );

            this.dadosRelatorio.lista.splice(index, 1, retorno.obj);
            toast(retorno.mensagens[0].descricao, "Sucesso", "success");
            this.resetEdit();
          } else {
            toast(retorno.mensagens[0].descricao, "Falha", "danger");
          }
        })
        .catch((error) => {
          desativarShow("saveEdit")
        });
    },

    confirmaCancelaFechamento(entity) {
      this.excludingRow = entity;
      this.msgConfirma = "Confirma exclusão do fechamento?";
      this.$bvModal.show('modal-conf-excl-fecha')
      //this.$refs['modal-conf-canc-fecha'].show();
    },
    cancelarFechamento() {

      const { toast, ativarShow, desativarShow } = appUtils(this)

      this.$bvModal.hide("modal-conf-excl-fecha");

      ativarShow("cancelarFechamento")

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

      let dadosEnvio =
        '{ "id":' +
        this.excludingRow.id +
        ', "idUsuario": ' +
        dadosUsuario.idPessoa +
        "}";

      AgendaService.cancelarFechamentoAgenda(dadosEnvio)
        .then((resposta) => {
          desativarShow("cancelarFechamento")
          let retorno = resposta.data;
          if (retorno.status === "SUCCESS") {
            this.dadosRelatorio.comFechamento = false;
            let index = this.dadosRelatorio.lista.indexOf(
              this.dadosRelatorio.lista.find(
                (l) => l.id === this.excludingRow.id
              )
            );
            this.dadosRelatorio.lista.splice(index, 1);
            toast(retorno.mensagens[0].descricao, "Sucesso", "success");
          } else {
            toast(retorno.mensagens[0].descricao, "Falha", "danger");
          }
          desativarShow("cancelarFechamento")
        })
        .catch((error) => {
          desativarShow("cancelarFechamento")
        });
    },
    resetEdit() {
      this.editingRow = null;
    },
    abrirAgenda(entity) {
      this.$router.push("/admin/agenda/fechamento/" + btoa(entity.id));
    },
    setSelectedSituacao(value) {
      alert(value);
    },
    abrirHistorico(entity) {
      this.buscarHistorico(entity);
    },
    buscarHistorico(entity) {

      const { ativarShow, desativarShow } = appUtils(this)

      ativarShow("buscarHistorico")

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));
      let dadosConsulta = (dadosConsulta =
        '{ "idUsuario": ' +
        dadosUsuario.idPessoa +
        ', "idFechamento": ' +
        entity.id +
        "}");
      AgendaService.listaHistoricoFechamento(this.http, dadosConsulta)
        .then((resposta) => {
          this.historicos = resposta.data.obj;
          desativarShow("buscarHistorico")
          this.$bvModal.show("modal-historico");
        })
        .catch((error) => {
          desativarShow("buscarHistorico")
        });
    },
    exportarJSoftware(entity) {

      const { toast, ativarShow, desativarShow } = appUtils(this)

      ativarShow("exportarJSoftware")

      let ids = [];
      this.dadosRelatorio.lista.filter(function (item) {
          return item.selecione;
        }).forEach((item) => {
          ids.push(item.id);
        })

        if (ids.length == 0) {
        ids.push(entity.id)
      }

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));
      let dadosConsulta = {
        "idUsuario": dadosUsuario.idPessoa,
        "ids": ids
      }

      AgendaService.exportarRelatorioFinanceiroExcel(dadosConsulta)
        .then((resposta) => {
          desativarShow("exportarJSoftware")
          let retorno = resposta.data;
          if (retorno.status === "SUCCESS") {
            var base64 = retorno.obj;
            this.downloadExcel(base64);
          } else {
            toast(retorno.mensagens[0].descricao, "Falha", "danger");
          }
        })
        .catch((error) => {
          desativarShow("exportarJSoftware")
        });
    },
    /*exportarExcel(entity) {
      
      const { toast, ativarShow, desativarShow } = appUtils(this)

      ativarShow("exportarExcel")

      let ids = [];
      this.dadosRelatorio.lista
        .filter(function (item) {
          return item.selecione;
        })
        .forEach((item) => {
          ids.push(item.id);
        })

        if (ids.length == 0) {
        ids.push(entity.id)
      }

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));
      let dadosConsulta = 
        { "idUsuario": dadosUsuario.idPessoa,
        "ids": ids
        }

      AgendaService.exportarRelatorioFinanceiroGeralExcel(
        this.http,
        dadosConsulta
      )
        .then((resposta) => {
          desativarShow("exportarExcel")
          let retorno = resposta.data;
          if (retorno.status === "SUCCESS") {
            var base64 = retorno.obj;
            this.downloadExcel(base64);
          } else {
            toast(retorno.mensagens[0].descricao, "Falha", "danger");
          }
        })
        .catch((error) => {
          desativarShow("exportarExcel")
        });

    },*/
    exportarExcel(entity) {
      
      const { toast, ativarShow, desativarShow } = appUtils(this)

      ativarShow("exportarExcel")

      let ids = [];
      this.dadosRelatorio.lista.filter(function (item) {
          return item.selecione;
        }).forEach((item) => {
          ids.push(item.id);
        });

      if (ids.length == 0) {
        ids.push(entity.id)
      }

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));
      let dadosConsulta = {
        "idUsuario": dadosUsuario.idPessoa,
        "idsFechamento": ids
        }

      AgendaService.exportarAgendaExcel(this.http, dadosConsulta)
        .then((resposta) => {
          desativarShow("exportarExcel")
          let retorno = resposta.data;
          if (retorno.status === "SUCCESS") {
            var base64 = retorno.obj;
            this.downloadExcel(base64);
          } else {
            toast(retorno.mensagens[0].descricao, "Falha", "danger");
          }
        })
        .catch((error) => {
          desativarShow("exportarExcel")
        });

    },
    exportarHmtj(entity, todos) {

      const { toast, ativarShow, desativarShow } = appUtils(this)

      ativarShow("exportarHmtj")

      let ids = [];
      if (todos) {
        this.dadosRelatorio.lista
          .filter(function (item) {
            return item.selecione;
          })
          .forEach((item) => {
            ids.push(item.id);
          });
      } else {
        ids.push(entity.id);
      }

      funcao.methods
        .exportarHmtj(ids)
        .then((resposta) => {
          desativarShow("exportarHmtj")
          let retorno = resposta.data;
          if (retorno.status === "SUCCESS") {
            var base64 = retorno.obj;
            funcao.methods.downloadTxt(base64);
          } else {
            toast(retorno.mensagens[0].descricao, "Falha", "danger");
          }
        })
        .catch((error) => {
          desativarShow("exportarHmtj")
        });
    },
    confirmaRelatorioConsolidado(entity) {
      this.entity = entity

      let colunas = []
      if (this.exibirValorVenda) colunas.push({ text: 'Valor de venda', value: 'valorVenda' })
      if (this.exibirValorCompra) colunas.push({ text: 'Valor de compra', value: 'valorCompra' })
      colunas.push({ text: 'Tipo de serviço', value: 'tipoServico' })      
      if (this.exibirValorVenda || this.exibirValorCompra) colunas.push({ text: 'Valor unitário', value: 'valorUnitario' })

      colunas.push({ text: 'PJ', value: 'razaoPJ' })

      this.$refs.confRelFinConsolidado.show(colunas)
    },
    confirmaRelatorioDetalhado(entity) {      
      this.entity = entity

      let colunas = []
      if (this.exibirValorVenda) colunas.push({ text: 'Valor de venda', value: 'valorVenda' })
      if (this.exibirValorCompra) colunas.push({ text: 'Valor de compra', value: 'valorCompra' })      

      if (colunas.length > 0) {
        this.$refs.confRelFinDetalhado.show(colunas)  
      } else {
        this.exportarExcelDetalhado()
      }

    },    
    exportarExcelConsolidado(op) {

      const { toast, ativarShow, desativarShow } = appUtils(this)

      ativarShow("exportarExcelConsolidado")

      let ids = [];
      this.dadosRelatorio.lista.filter(function (item) {
        return item.selecione;
      }).forEach((item) => {
        ids.push(item.id);
      });

      if (ids.length == 0) {
        ids.push(this.entity.id)
      }

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

      let dadosEnvio = {
        "idUsuario": dadosUsuario.idPessoa,
        "idTabela": dadosUsuario.grupo.idTabela,
        "idEmpresa": dadosUsuario.perfilCoordenador === 'S' ? dadosUsuario.grupo.empresa.id : null,        
        "idsFechamento": ids,
        "dtInicio": this.dtInicio,
        "dtFim": this.dtFim,
        "comPrecoVenda": op.outrasColunasSelecionadas && op.outrasColunasSelecionadas.filter((p) => { return p === 'valorVenda' }).length == 1 ? 'S' : 'N',
        "comPrecoCompra": op.outrasColunasSelecionadas && op.outrasColunasSelecionadas.filter((p) => { return p === 'valorCompra' }).length == 1 ? 'S' : 'N',        
        "comTipoServico": op.outrasColunasSelecionadas && op.outrasColunasSelecionadas.filter((p) => { return p === 'tipoServico' }).length == 1 ? 'S' : 'N',
        "comPrecoUnitario": op.outrasColunasSelecionadas && op.outrasColunasSelecionadas.filter((p) => { return p === 'valorUnitario' }).length == 1 ? 'S' : 'N',
        "comRazaoPJ": op.outrasColunasSelecionadas && op.outrasColunasSelecionadas.filter((p) => { return p === 'razaoPJ' }).length == 1 ? 'S' : 'N'
      }

      AgendaService.exportarRelatorioFinanceiroConsolidado(dadosEnvio)
        .then((resposta) => {
          desativarShow("exportarExcelConsolidado")
          let retorno = resposta.data;
          if (retorno.status === "SUCCESS") {
            var base64 = retorno.obj;
            funcao.methods.downloadExcel(base64);
          } else {
            toast(retorno.mensagens[0].descricao, "Falha", "danger");
          }
        })
        .catch((error) => {
          desativarShow("exportarExcelConsolidado")
        });
    },
    exportarExcelDetalhado(op) {
      const { toast, ativarShow, desativarShow } = appUtils(this)

      ativarShow("exportarExcelDetalhado")

      let ids = [];
      this.dadosRelatorio.lista.filter(function (item) {
        return item.selecione;
      }).forEach((item) => {
        ids.push(item.id);
      });

      if (ids.length == 0) {
        ids.push(this.entity.id)
      }

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

      let dadosEnvio = {
        "idUsuario": dadosUsuario.idPessoa,
        "idTabela": dadosUsuario.grupo.idTabela,
        "idEmpresa": dadosUsuario.perfilCoordenador === 'S' ? dadosUsuario.grupo.empresa.id : null,
        "idsFechamento": ids,
        "dtInicio": this.dtInicio,
        "dtFim": this.dtFim,
        "comPrecoVenda": op && op.outrasColunasSelecionadas && op.outrasColunasSelecionadas.filter((p) => { return p === 'valorVenda' }).length == 1 ? 'S' : 'N',
        "comPrecoCompra": op && op.outrasColunasSelecionadas && op.outrasColunasSelecionadas.filter((p) => { return p === 'valorCompra' }).length == 1 ? 'S' : 'N',        
        "comTipoServico": op && op.outrasColunasSelecionadas && op.outrasColunasSelecionadas.filter((p) => { return p === 'tipoServico' }).length == 1 ? 'S' : 'N',
        "comPrecoUnitario": op && op.outrasColunasSelecionadas && op.outrasColunasSelecionadas.filter((p) => { return p === 'valorUnitario' }).length == 1 ? 'S' : 'N'
      }

      AgendaService.exportarRelatorioFinanceiroDetalhado(dadosEnvio)
        .then((resposta) => {
          desativarShow("exportarExcelDetalhado")
          let retorno = resposta.data;
          if (retorno.status === "SUCCESS") {
            var base64 = retorno.obj;
            funcao.methods.downloadExcel(base64);
          } else {
            toast(retorno.mensagens[0].descricao, "Falha", "danger");
          }
        })
        .catch((error) => {
          desativarShow("exportarExcelDetalhado")
        });
    },
    downloadExcel(data) {
      var temp =
        "data:application/vnd.ms-excel;base64," + encodeURIComponent(data);
      var download = document.createElement("a");
      download.href = temp;
      download.download = "arquivo.xlsx";
      document.body.appendChild(download);
      download.click();
      document.body.removeChild(download);
    },
    downloadTxt(data) {
      var temp = "data:text/plain;base64," + encodeURIComponent(data);
      var download = document.createElement("a");
      download.href = temp;
      download.download = "arquivo.txt";
      document.body.appendChild(download);
      download.click();
      document.body.removeChild(download);
    },
    strDateFormat(dataStr, format) {
      let data = new Date(dataStr + " (UTC-3)");
      return dateToStr(data, format);
    },
    hasPermission(menu, permissao, nivel) {
      return this.$store.getters['auth/hasPermission'](menu, permissao, nivel)
    },
    selecionarTodos(checked) {
      this.dadosRelatorio.lista.forEach((item) => {
        item.selecione = checked;
      });
    },
  },
  computed: {
    rows() {
      return this.dadosRelatorio.itens === undefined
        ? 0
        : this.dadosRelatorio.itens.length;
    },
    editableFields() {
      return this.fields.filter((field) => {
        return field.editable === true;
      });
    },
  },
};

function dateToStr(date, format) {
  if (date !== undefined && date != null) {
    let strDate = format
      .replace("yyyy", date.getFullYear())
      .replace("MM", (date.getMonth() + 1).toString().padStart(2, "0"))
      .replace("dd", date.getDate().toString().padStart(2, "0"))
      .replace("HH", date.getHours().toString().padStart(2, "0"))
      .replace("mm", date.getMinutes().toString().padStart(2, "0"))
      .replace("ss", date.getSeconds().toString().padStart(2, "0"));
    return strDate;
  }
}
</script>
<style>
  .c-form {
    padding-bottom: 60px;
  }

  .tela-fechamentos .fonte-local{
    font-size: 0.7rem;
  }

/*.tableCustom {
  padding: 12px 8px;
  font-size: 12px;
  vertical-align: middle;
  width: 20% !important;
  height: 100%;
}*/
</style>
